import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';

const ProtectedRoute = ({ component: Component, token, roles, ...rest }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!token || roles[0] !== "ROLE_ADMIN") {
      navigate('/login');
    }
  }, [token, roles, navigate]);


  return <Component {...rest} />;
};

const mapStateToProps = (state) => ({
  token: state.user.token,
  roles: state.user.roles,
});

export default connect(mapStateToProps)(ProtectedRoute);
