
//------API_BASE_URL (AnasDigital comments)

export const API_BASE_URL = 'https://ba.serveurproduction.xyz/api/';
export const BASE_URL = 'https://ba.serveurproduction.xyz/upload/images/';



//------Category (AnasDigital comments)
export const CATEGORY_ENDPOINT ='categories'

//------Category (AnasDigital comments)
export const CATEGORY_BY_PROFILE_ENDPOINT ='category'

//------Events (AnasDigital comments)
export const EVENTS_ENDPOINT ='events'

//------register (AnasDigital comments)
export const REGISTER_ENDPOINT ='register'

//------login (AnasDigital comments)
export const LOGIN_CHECK_ENDPOINT ='login'

//------covers (AnasDigital comments)
export const COVERS_ENDPOINT ='covers'

//------references (AnasDigital comments)
export const REFERENCES_ENDPOINT ='references'

//------intervention (AnasDigital comments)
export const INTERVENTION_SPECIALTIES_ENDPOINT ='specialities'

//------languages (AnasDigital comments)
export const LANGUAGES_ENDPOINT ='languages'

//------parcours (AnasDigital comments)
export const PARCOURS_ENDPOINT ='parcours'

//------architect (AnasDigital comments)
export const ARCHITECT_PROFILES_ENDPOINT ='profiles'

//------architect (AnasDigital comments)
export const ARCHITECT_BY_PROFILES_ENDPOINT ='profile'

export const UPDATE_PROFILE_ENDPOINT ='profile/current'

//------comments (AnasDigital comments)
export const COMMENTS_ENDPOINT ='comments'

//------replies(AnasDigital comments)
export const REPLIES_ENDPOINT ='replies'

//------updateuser(AnasDigital comments)
export const UPDATE_USER_ENDPOINT ='users/current'



//------posts (AnasDigital comments)
export const POSTS_ENDPOINT ='posts'

//------feedbacks (AnasDigital comments)
export const FEEDBACKS_ENDPOINT ='feedbacks'
export const FEEDBACK_ENDPOINT ='feedback'
//------Media (AnasDigital comments)
export const MEDIA_ENDPOINT ='media'

export const BULK_ENDPOINT ='bulk-update'

export const USERS_ENDPOINT ='users'
export const USERBYCATEGORY_ENDPOINT ='user/pack_category'

export const BANNERS_ENDPOINT ='banners'
export const BANNERSP_ENDPOINT ='pubs'

export const PROFILES_REPORTS_ENDPOINT ='profile_reports'
export const POSTS_REPORTS_ENDPOINT ='post_reports'
export const COMMENTS_REPORTS_ENDPOINT ='comment_reports'
export const PROFILEREQUEST_ENDPOINT ='profile_requests'









