import React from 'react'

export  const  BorderTitle = ({title}) => {
  return (
    <div
  className=""
  style={{  }}
 >
<span className="txt-l primarytxt fw-bold" >{title}</span>
<div
  className="mt-2"
  style={{ backgroundColor:'var(--third)',borderRadius:4 }}
 >
<div
  className=""
  style={{ backgroundColor:'var(--primary)',width:50,borderRadius:4,paddingTop:3 }}
 >

 </div>
 </div>
 </div>
  )
}
