import React, { useState } from 'react'

function Index() {

  const [tabs, setTabs] = useState(0);
  const handleFileUpload = () => {
    // Trigger the file input element when the button is clicked
    document.getElementById('fileInput').click();
  };

  const handleFileChange = (e) => {
    // Handle the file change event and setTabs(1) when a file is selected
    setTabs(1);
    // You can also access the uploaded file using e.target.files[0]
  };

  return (
    <div className='container-fluid' style={{backgroundColor:"#0F0F0F",minHeight:'100vh'}} >
       <div className='row ' style={{}} >
         <div className='col-12 text-center ' style={{}} >
      <img src='https://i.ibb.co/rxzFbJ8/removal-ai-d622a4f4-22ad-4a76-bb4a-8aa8fc17ed9b-logoscan-1.png' style={{width:200}} className='img-fluid mx-auto' />
</div></div>
      {tabs==0 ?
      <div className='container-fluid ' style={{}} >
        
        <div className='container ' style={{}} >
      <div className='row ' style={{}} >
      <div className='col-6 pt-5' style={{}} >
      
      <h1 className=' txt-xxxl text-white ' >
ScanSpark
      </h1>
      <p className=' txt-l text-white ' >
      Quick OCR: Transforming text from images with<br/> lightning speed.
      </p>
      <div>
      <button
        onClick={handleFileUpload}
        className='btn'
        style={{
          padding: 10,
          backgroundColor: '#D72726',
          minWidth: 200,
          borderRadius: 8,
          color: 'white'
        }}
      >
        Upload Your Target
      </button>
      <input
        type="file"
        id="fileInput"
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />
    </div>

      
      </div>
      <div className='col-6' style={{}} >
      
        <img src='https://asliri.id/images/ocr/seamless_data.webp' style={{width:650}} className='img-fluid'  />
      </div>
      
      </div>     </div>
      </div>
      :
      <div className='container-fluid text-center' style={{}} >
             <div className='row' style={{}} >
             <div className='col-6' style={{}} >
          <div className='scanner mx-auto ' style={{width:500,}} >
          <img src='https://i.ibb.co/mz7P4ZS/image-1.png' style={{width:400,}} className='img-fluid mb-3'  />
          {/* <img src='https://i.ibb.co/mz7P4ZS/image-1.png' style={{width:300,height:150}} className='img-fluid'  /> */}
        
        </div>
        </div>
        <div className='col-6' style={{}} >
          <p className='text-white txt-l' >First Name : Zaineb </p>
          <p className='text-white txt-l' >Last Name : El Alami </p>
          <p className='text-white txt-l' >BirthDay : 05/12/1983 </p>
          <p className='text-white txt-l' >City : Ouarzazat </p>
          <p className='text-white txt-l' >الاسم الأول: زينب</p>
          <p className='text-white txt-l' >الاسم الأخير : العلمي </p>
          <p className='text-white txt-l' >تاريخ الميلاد : 12/05/1983 </p>
          <p className='text-white txt-l' >المدينة : ورزازات</p>
        </div>
        </div>
        <div className='scanner mx-auto' style={{width:500,height:250,position:'relative'}} >
        

        </div>
        </div>
} 

      </div>
  )
}

export default Index