import images from "../../../config/images";
import React, { useEffect, useState } from "react";
import "./style.css";
import apiMethods from "../../../config/services/ApiService";
import { BASE_URL } from "../../../config/constants/apiEndpoints";
import { CustomModal } from "../../../components/modal";
import Dashboard from "../index";
import {Data1} from './data1'
import {Data2} from './data2'
import {Data3} from './data3'
import {Data4} from './data4'


const Index = () => {
  
  return (
<Dashboard>
    <>
      <div className="">
        {/* page title */}
        <span className=" txt-l blacktxt fw-bold ">Dashboard</span>
        <br></br>
        <span className=" txt-xs graytxt">Dashboard /</span>
        {/* page title end  */}
        <div className="row">
        <div className="col-md-6 col-sm-12 mb-3">
          {/* data card */}
        <div className="a-card-container whitebg mt-3  ">
        <span className=" txt-l blacktxt fw-bold ">Users</span>
        <br></br>
        <span className=" txt-xs graytxt">Cities</span>    <br></br>
        <div className=" " style={{width:400,height:400,margin:'auto'}} >
        <Data1 />
        </div>
         </div>
     {/* data card end */}
          </div>
          <div className="col-md-6 col-sm-12 mb-3">
          {/* data card */}
        <div className="a-card-container whitebg mt-3  ">
        <span className=" txt-l blacktxt fw-bold ">Users</span>
        <br></br>
        <span className=" txt-xs graytxt">Cities</span>    <br></br>
        <div className=" " style={{width:400,height:400,margin:'auto'}} >
        <Data2  />
        </div>
         </div>
     {/* data card end */}
          </div>
          <div className="col-md-6 col-sm-12 mb-3">
          {/* data card */}
        <div className="a-card-container whitebg mt-3  ">
        <span className=" txt-l blacktxt fw-bold ">Users</span>
        <br></br>
        <span className=" txt-xs graytxt">Cities</span>    <br></br>
        <div className=" " style={{width:400,height:200,margin:'auto'}} >
        <Data3  />
        </div>
         </div>
     {/* data card end */}
          </div>
          <div className="col-md-6 col-sm-12 mb-3">
          {/* data card */}
        <div className="a-card-container whitebg mt-3  ">
        <span className=" txt-l blacktxt fw-bold ">Users</span>
        <br></br>
        <span className=" txt-xs graytxt">Cities</span>    <br></br>
        <div className=" " style={{width:400,height:200,margin:'auto'}} >
        <Data4 />
        </div>
         </div>
     {/* data card end */}
          </div>

          </div>
        
      </div>

 
   
    </>
</Dashboard>
  );
};

export default Index;
