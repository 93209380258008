import React from "react";
import "./style.css";
import apiMethods from "../../config/services/ApiService";
import { useCallback } from "react";
import { BASE_URL } from "../../config/constants/apiEndpoints";
import  {Nav}  from "../../components/Nav";
import Footer from "../../components/Footer";
import { useState } from "react";
import { useParams } from "react-router-dom";
import images from "../../config/images";
import { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar, A11y, Pagination ,Navigation} from 'swiper/modules';
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { WrappedMap, Profiles, MapComponent } from "./components";
const Index = () => {
  const params = useParams();
  const [userinfo, setUserinfo] = useState([]);

  const [tabs, setTabs] = useState(0);

  const [loading, setLoading] = useState(false);

  const [buttonLoading, setButtonLoading] = useState(false);
  const noimage = BASE_URL + "istockphoto-1131164548-610x610.png";
  const [search, setSearch] = useState("");
  const fetchUserInfo = async () => {
    setButtonLoading(true);
    setLoading(true)
    try {
      const response = await apiMethods.getAllProfiles("desc",search);
      if (response.data) {
        const userData = response.data.data;
        setUserinfo(userData);
        console.log("Here is  the profle :");
        console.log(userData);
        setLoading(false);
        setButtonLoading(false);
      } else {
      }
    } catch (error) {
      console.error("Error fetching userinfo: ", error);
    }
  };

  const [activeTab, setActiveTab] = useState('all'); 
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 850) {
        setActiveTab('all');
      }else{
        setActiveTab('tab1');
      }
    };
  
    window.addEventListener('resize', handleResize);
  
    // Call once initially
    handleResize();
  
    return () => {
      // Cleanup
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  useEffect(() => {
    fetchUserInfo()
  }, [search]);

  
  return (
<div className='' style={{maxWidth:'100'}} >
 
<Nav/>
<div className='container-fluid mt-3 pb-5' style={{}} >
<div className='container-fluid mb-5 mb-5' style={{height:'100vh'}} >

<div className='row mt-0 ' style={{}} >
<div className='col-12' style={{}} >
<button
  className="d-block d-max-lg-none a-btn primary mb-2 w-100"
  onClick={() => setActiveTab(activeTab === 'tab1' ? 'tab2' : 'tab1')}
>
  {activeTab === 'tab1' ? 'Map View' : 'List View'}
</button>
</div>

{activeTab === 'all' && (
 <>
 <div className='col-lg-5 col-l-5 col-md-6 col-12' style={{overflowY:'hidden', height:'100vh'}} >

 <div className='container-fluid ' style={{}} >
<div className="row pb-3  my-auto mb-2">
<div className="col my-auto">
                <div className="d-flex  lightbg btn-radius ">
                  <svg
                    className="mx-auto my-auto ms-2"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      opacity="0.5"
                      x="17.0365"
                      y="15.1223"
                      width="8.15546"
                      height="2"
                      rx="1"
                      transform="rotate(45 17.0365 15.1223)"
                      fill="currentColor"
                    ></rect>
                    <path
                      d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                  <input
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    className="ms-2 lightbg inputs-container w-100"
                    placeholder="Rechereche"
                  />
                </div>
                </div> 
                <div className="col-auto">
                <button className=" a-btn-small primary w-100 " onClick={fetchUserInfo} >
              {/* <i className="me-1 fa-solid fa-sliders whitetxt txt-xs  " /> */}
              {!buttonLoading ? (
                  <span className="whitetxt" >Refresh</span>
                ) : (
                  <img
                    className="mx-auto my-auto"
                    src={images.spinner}
                    style={{ width: 20 }}
                  />
                )}
                
              </button> 
                </div>
              </div> 
              </div>
              <div className='container-fluid ' style={{}} >
<div className='row' style={{overflowY: 'auto', maxHeight: '100vh',paddingBottom:60}} >
  {    loading==false ? (
<> 
{userinfo.map((item, index) => {
  return(
    <div className='col-lg-12 col-md-12' style={{}} >
<Profiles key={index} item={item} />
</div>
)
})}
</>
):
(
  <div
  className="container-fluid d-flex bg-white p-0 m-0"
  style={{ minHeight: "100vh" }}
>
  <div className='mx-auto my-auto' style={{}} >
      <img src={images.spinner} style={{width:50,height:50}} />
       </div>
       </div>
 )
}
  </div>
  </div>
  </div>
  <div className='col-lg-7 col-l-7 col-md-6  col-12' style={{height:'100vh'}} >
<MapComponent items={userinfo} />
  </div>
</>
)}


{activeTab !== 'all' && (
    <div className="row d-max-lg-none mb-5  ">
{activeTab === 'tab1' ? (
<div className='col-lg-5 col-l-5 col-md-6 col-12 mb-5' style={{overflowY:'hidden', height:'100%',maxHeight: '100vh',}} >


<div className="row pb-3 mt-0  mb-2">
<div className="col  my-auto">
                <div className="d-flex  lightbg btn-radius w-100">
                  <svg
                    className="mx-auto my-auto ms-2"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      opacity="0.5"
                      x="17.0365"
                      y="15.1223"
                      width="8.15546"
                      height="2"
                      rx="1"
                      transform="rotate(45 17.0365 15.1223)"
                      fill="currentColor"
                    ></rect>
                    <path
                      d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                  <input
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    className="ms-2 lightbg inputs-container w-100"
                    placeholder="Rechereche"
                  />
                </div>
                </div> 
                <div className="col-auto">
                <button className=" a-btn-small primary w-100 " onClick={fetchUserInfo} >
              {/* <i className="me-1 fa-solid fa-sliders whitetxt txt-xs  " /> */}
              {!buttonLoading ? (
                  <span className="whitetxt" >Refresh</span>
                ) : (
                  <img
                    className="mx-auto my-auto"
                    src={images.spinner}
                    style={{ width: 20 }}
                  />
                )}
                
              </button> 
                </div>
              </div> 
<div className='row' style={{overflowY: 'auto', maxHeight: '100vh'}} >
  {    loading==false ? (
<> 
<span className="txt-l fw-bold mb-2 txt-color-1" >{userinfo.length} Results</span>
{userinfo.map((item, index) => {
  return(
    <div className='col-lg-12 col-md-12' style={{}} >
<Profiles key={index} item={item} />
</div>
)
})}
</>
):
(
  <div
  className="container-fluid d-flex bg-white p-0 m-0"
  style={{ minHeight: "100vh" }}
>
  <div className='mx-auto my-auto' style={{}} >
      <img src={images.spinner} style={{width:50,height:50}} />
       </div>
       </div>
 )
}
  </div>
  </div>
) : (
  <div className='col-lg-7 col-l-7 col-md-6  col-12' style={{ maxHeight: '100vh',height:'100vh'}} >
{/*     <WrappedMap items={userinfo} /> */}
<MapComponent items={userinfo} />
  </div>
)}
  </div>
)}
  </div>
  </div>
 
  </div>
  <Footer/>

  </div>

     
    
  
  );
};

export default Index;
