

import { BASE_URL } from "../../config/constants/apiEndpoints";
import { useDateFormat } from "../../config/hooks/general";
import images from "../../config/images";
import React, { useEffect, useState,useRef } from "react";
import apiMethods from '../../config/services/ApiService'
import { Link } from "react-router-dom";
import { GoogleMap, useJsApiLoader, Marker,OverlayView  } from '@react-google-maps/api';



const getPixelPositionOffset = (width, height) => ({
    x: -(width / 2),
    y: -(height / 2),
  });
  
  const CustomMarker = ({ position, text,avatar,id }) => (
    <OverlayView
      position={position}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      getPixelPositionOffset={getPixelPositionOffset}
    >
          <Link style={{textDecoration:'none'}} to={`/profile/${id}`}>
        <img src={ avatar ?  BASE_URL+ avatar : images.noimage} style={{width:40,height:40,borderRadius:100,border:'2px solid #fff',boxShadow: "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px"}}  />
        </Link>
 
    </OverlayView>
  );
const containerStyle = {
    width: '100%',
    height: 300
  };
  
 

  
  export const MapComponent = ( {item} ) => {
    const { isLoaded } = useJsApiLoader({
      id: 'google-map-script',
      googleMapsApiKey: "AIzaSyCNq53xRUJyTM2USlmPvOKfnaRha-f3xCI" 
    });
  
    const latitude = parseFloat(item.lalt);
    const longitude = parseFloat(item.longt);
    const center = {
        lat: latitude,
        lng: longitude
      };
    

    // Ensure latitude and longitude are valid numbers
    if (isNaN(latitude) || isNaN(longitude)) {
      return null;  // Don't render this Marker
    }
    return isLoaded ? (
      <GoogleMap
        mapContainerStyle={containerStyle}
        zoom={5}
        center={center}
      >
        
            <CustomMarker id={item.id} avatar={item.avatar}   position={{ lat: latitude, lng: longitude }} />
      
        
      </GoogleMap>
    ) : <>
    <span>no map</span>
    </>;
  };
  
  export default React.memo(MapComponent);