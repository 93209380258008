
import Sidebar from '../../components/Sidebar';
import images from '../../config/images'
import React, { useEffect, useState } from "react";
import apiMethods from '../../config/services/ApiService'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Scrollbar, A11y, Pagination ,Navigation} from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { BASE_URL } from '../../config/constants/apiEndpoints';
import { useDateFormat } from '../../config/hooks/general';
import { NumbersRender,CategoriesRender, Events, EventsRender, HeaderRender, HowRender, ProfilesRender, WhoRender,As } from './component';
import Footer from '../../components/Footer'
import Footer2 from '../../components/Footer2'




const Home = () => {

  return(
  <div className="container-fluid">
    {/* header */}
    <HeaderRender/>
    {/* header end */}

    <NumbersRender/>

    {/* categories */}
                    <CategoriesRender/>
    {/* categories end */}

    {/* jobs */}
          <ProfilesRender/>
    {/* jobs end */}

      {/* who we are  */}
 <WhoRender/>
    {/*  who we are end */}

        {/* how */}
        <HowRender/>
    {/* how end */}
    
  
{/*  as */}
    <As/>
{/*  as end */}

    {/* Zvents  */}
          <EventsRender/>
    {/*  Zvents end */}
    {/*  footer 1 */}
    <Footer2/>
    {/*  footer 1 end */}
    {/*  footer 2 */}
            <Footer/>
    {/*  footer 2 end */}
  </div>
  )

          };

export default Home;
