import { format } from 'date-fns';

export function useDateFormat(dateString) {
    const formatString = 'dd-MM-yyyy';
    const date = new Date(dateString);
  
    // Check if date is invalid
    if (isNaN(date)) {
      return "";
    }
  
    return format(date, formatString);
  }
  
  
  export const hexToRgba = (hex, alpha = 1) => {
    hex = hex.replace('#', '');
    const r = parseInt(hex.slice(0, 2), 16);
    const g = parseInt(hex.slice(2, 4), 16);
    const b = parseInt(hex.slice(4, 6), 16);
    return `rgba(${r},${g},${b},${alpha})`;
  };
  
  
  export function EmptyData(data) {
      if(data=="" || data==null  ){
          return "à compléter"
      }else{
          return data
      }
    }
    
    export function UpperCase(data) {
      if(data!=""){
        const uppercaseText = data.toUpperCase();
        return uppercaseText
      }else{
        return data
      }
      
  
    }
    
  
  
  