
import Sidebar from '../../components/Sidebar';
import images from '../../config/images'
import React, { useEffect, useState } from "react";
import './style.css';
import { DashboardHeader } from './component';
import  Categories  from './Categories';




const Index = ({children}) => {

  return(
      <div className="dashboard">
      <Sidebar />
      <div className="main-content p-0 m-0">
        {/* <DashboardHeader/> */}
        <div className="p-3 pt-5" style={{maxWidth:1400,margin:'auto'}} >
        {children}
        </div>
      </div>
   
    </div>
  )

          };

export default Index;
