import React from "react";
import "./style.css";
import apiMethods from "../../config/services/ApiService";
import { useCallback } from "react";
import { BASE_URL } from "../../config/constants/apiEndpoints";
import { useState } from "react";
import { useParams } from "react-router-dom";
import images from "../../config/images";
import { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar, A11y, Pagination ,Navigation} from 'swiper/modules';
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Nav } from "../../components/Nav";
import { MapComponent } from "./components";
const Index = () => {
  const params = useParams();
  const [userinfo, setUserinfo] = useState({
    user: {},
    parcours: [],
    languages: [],
    covers: [],
    feedback: [],
    reference: [],
    specialities: [],
  });

  const [tabs, setTabs] = useState(0);

  const [loading, setLoading] = useState(false);
  const [loadingError, setLoadingError] = useState(false);

  const noimage = BASE_URL + "istockphoto-1131164548-610x610.png";

  const fetchUserInfo = async () => {
    setLoading(true)
    try {
      const response = await apiMethods.getProfileByID(params.id);
      if (response.data) {
        const userData = response.data;
        setUserinfo(userData);
        setLoading(false)
      } else {
      }
    } catch (error) {
      console.error("Error fetching userinfo: ", error);
      setLoadingError(true);
      setLoading(false)
    }

  };

  useEffect(() => {
    fetchUserInfo();
  }, []);

  return (
    <>
    <Nav/>
{loading === false && loadingError === false ? (
  <div
    className="container-fluid bg-light p-0 m-0"
    style={{ minHeight: "100vh" }}
   >
      <div
        className="row m-0 p-0  w-100 position-relative "
        style={{
          minHeight: "500px",
          width: "100%",
          background: `url("${
            userinfo.covers[0]
              ? BASE_URL + userinfo.covers[0].url
              : images.noimage
          }")`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div
          className=" d-flex position-absolute w-100 h-100 "
          style={{
            background: "linear-gradient(to bottom, transparent, black)",
          }}
        >
          <div className="container mt-auto pb-3" style={{}}>
            <div className="row pb-5" style={{}}>
              <div className="col-12 text-center mb-3" style={{}}>
                <img
                  src={userinfo.avatar ? BASE_URL + userinfo.avatar : images.noimage}
                  style={{
                    height: 200,
                    width: 200,
                    borderRadius: "100%",
                    border: "2px solid var(--white)",
                  }}
                />
              </div>
              <div className="col mb-2" style={{}}>
                <h2 className=" whitetxt fw-bold mb-3 ">
                {userinfo.slogo ? userinfo.slogo : "à compléter"}
                 </h2>
                <div className="row" style={{}}>
                  <div className="col-auto" style={{}}>
                    <div className="row" style={{}}>
                      <div className="col-auto pe-1" style={{}}>
                        <i class="fa-solid fa-location-dot primarytxt  txt-sm"></i>
                      </div>
                      <div className="col-auto" style={{}}>
                        <span className="whitetxt txt-sm" style={{}}>
                       
                          {userinfo.address ? userinfo.address : "à compléter"}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-auto" style={{}}>
                    <div className="row" style={{}}>
                      <div className="col-auto pe-1" style={{}}>
                        <i class="fa-solid fa-phone primarytxt txt-sm"></i>
                      </div>
                      <div className="col-auto" style={{}}>
                        <span className="whitetxt txt-sm" style={{}}>
                      
                          {userinfo.officenumber ? userinfo.officenumber : "à compléter"}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-auto" style={{}}>
                    <div className="row" style={{}}>
                      <div className="col-auto pe-1" style={{}}>
                        <i class="fa-regular fa-envelope primarytxt txt-sm"></i>
                      </div>
                      <div className="col-auto" style={{}}>
                        <span className="whitetxt txt-sm " style={{}}>
                     
                          {userinfo.officeemail ? userinfo.officeemail : "à compléter"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-auto mx-auto " style={{}}>
                <div
                  className="row"
                  style={{
                    
                    padding: 8,
                    borderRadius: 50,
                  }}
                >
                  <div className="col-auto pe-0 my-auto" style={{}}>
                    <div
                      className=" d-flex text-center"
                      style={{
                        borderRadius: "100%",
                        backgroundColor: "var(--primary)",
                        width: 40,
                        height: 40,
                      }}
                    >
                      <span className=" mx-auto my-auto whitetxt txt-md fw-bold ">
                        {userinfo.averageStarRating}{" "}
                      </span>
                    </div>
                  </div>
                  <div className="col-auto my-auto" style={{}}>
                    <div className="row" style={{}}>
                      <div className="col">
                        <i
                          className={`${
                            userinfo.averageStarRating > 0
                              ? "fa-solid  yellowtxt"
                              : "fa-regular whitetxt"
                          } fa-star  txt-xs`}
                        />
                        <i
                          className={`${
                            userinfo.averageStarRating > 1
                              ? "fa-solid yellowtxt "
                              : "fa-regular whitetxt"
                          } fa-star  txt-xs`}
                        />
                        <i
                          className={`${
                            userinfo.averageStarRating > 2
                              ? "fa-solid  yellowtxt"
                              : "fa-regular whitetxt"
                          } fa-star  txt-xs`}
                        />
                        <i
                          className={`${
                            userinfo.averageStarRating > 3
                              ? "fa-solid  yellowtxt"
                              : "fa-regular whitetxt"
                          } fa-star  txt-xs`}
                        />
                        <i
                          className={`${
                            userinfo.averageStarRating > 4
                              ? "fa-solid yellowtxt "
                              : "fa-regular whitetxt"
                          } fa-star  txt-xs`}
                        />
                      </div>
                    </div>
                    <span className=" mx-auto my-auto whitetxt txt-xs  ">
                      {userinfo.feedbackCount} Reviews{" "}
                    </span>
                  </div>
                  <div className="col-auto mx-auto my-auto ms-2" style={{}}>
                    <i class="fa-regular fa-comment  txt-xl  graytxt "></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row m-0 p-0 mt-4 pb-3 " style={{}}>
        <div className="container whitebg p-4 card-shadow " style={{borderRadius:16}}>

          <div className="row" style={{}}>
          <div className="col-md-12 mb-3" style={{}}>
          <div className="row" style={{}}>
          <div className="col-auto pb-2" style={{borderBottom:'2px solid var(--primary)'}}>
                  <span className=" primarytxt txt-l " >Details</span>
                  </div>
                 {/* <div className="col-auto" style={{}}>
                  <span className=" txt-l " >Feedbacks</span>
                  </div> */}
                  </div>
            </div>
            <div className="col-md-8 col-sm-10" style={{}}>


     {/* card */}
            <div className="mb-3 card-shadow">
                <div
                  className="p-3  lightbg"
                  style={{
                    borderTopLeftRadius: 8,
                    borderTopRightRadius: 8,
                    
                  }}
                >
                  <span className=" txt-md fw-bold blacktxt ">
                    Localisation
                  </span>
                </div>
                <div
                  className="p-3 whitebg"
                  style={{
                  
                  }}
                >
                  {userinfo.lalt ||  userinfo.longt ? 
                  <MapComponent item={userinfo} />
                  :
                  <div
                  className="position-relative"
                  style={{
                  
                  }}
                >
                  <img className="img-fluid" src={images.map} style={{height:300}} />

                  <span className="txt-md whitetxt fw-bold" style={{position:'absolute',left:'42%',top:'50%'}} >à compléter</span>
                  </div> 
                }
                       </div> 
                </div> 
     {/* card  end*/}
              {/* card */}
              <div className="mb-3 card-shadow">
                <div
                  className="p-3  lightbg"
                  style={{
                    borderTopLeftRadius: 8,
                    borderTopRightRadius: 8,
                    
                  }}
                >
                  <span className=" txt-md fw-bold blacktxt ">
                    Spécialité D'intervention
                  </span>
                </div>
                <div
                  className="p-3 whitebg"
                  style={{
                    
                  }}
                >
                  {/* <span className=' txt-l fw-bold blacktxt ' >Spécialité D'intervention</span> */}
                  {userinfo.specialities.length!==0 ?  (
                  <div className="row mt-2" style={{}}>
                    {userinfo.specialities.map((item, index) => (
                      <div className="col-md-6 mb-2 col-sm-10" style={{}}>
                        <div className="row" style={{}}>
                          <div className="col-auto" style={{}}>
                            <div
                              className="lightbg text-center  d-flex "
                              style={{
                                width: 40,
                                height: 40,
                                borderRadius: "100%",
                              }}
                            >
                              <i class="fa-solid fa-check mx-auto my-auto primarytxt "></i>
                            </div>
                          </div>
                          <div className="col my-auto" style={{}}>
                            <span className=" txt-sm  blacktxt ">
                              {item?.name}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  ) : 
                  (

<div className="text-center align-items-center" style={{
    height: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
}}>
    <div>
    
        <p className="primarytxt mt-2 txt-sm"> à compléter</p>
    </div></div>
         
         )
}

                </div>
              </div>
              {/* card end */}

              {/* card */}
              <div className="mb-3 card-shadow">
                <div
                  className="p-3  lightbg"
                  style={{
                    borderTopLeftRadius: 8,
                    borderTopRightRadius: 8,
                    
                  }}
                >
                  <span className=" txt-md fw-bold blacktxt ">Références</span>
                </div>
                <div
                  className="p-3 whitebg"
                  style={{
                    
                  }}
                >
                  {/* <span className=' txt-l fw-bold blacktxt ' >Spécialité D'intervention</span> */}
                  {userinfo.reference.length!==0 ?  (

 <div className="row mt-2" style={{}}>
                    <Swiper
                      slidesPerView={1}
                      spaceBetween={10}
                      pagination={{
                        clickable: true,
                      }}
                      navigation
                        
                      
                      breakpoints={{
                        640: {
                          slidesPerView: 1,
                          spaceBetween: 20,
                        },
                        768: {
                          slidesPerView: 1,
                          spaceBetween: 40,
                        },
                        1024: {
                          slidesPerView: 1,
                          spaceBetween: 50,
                        },
                      }}
                      modules={[Pagination,Navigation]}
                      className="mySwiper"
                    >
                      {userinfo.reference.map((item, index) => (
                        <SwiperSlide key={index}>
                          <div className="col-sm-12" style={{}}>
                            <div
                              className="row m-0 p-0  w-100 position-relative "
                              style={{
                                height: "350px",
                                width: "100%",
                                background: `url("${
                                  item.url
                                    ? BASE_URL + item.url
                                    : images.noimage
                                }")`,
                                backgroundPosition: "center",
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                borderRadius: 8,
                              }}
                            />
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
) : 
                  (

<div className="text-center align-items-center" style={{
    height: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
}}>
    <div>
    
        <p className="primarytxt mt-2 txt-sm"> à compléter</p>
    </div></div>
                  )
}
                 

                </div>
              </div>
              {/* card end */}

              {/* card */}
              <div className="mb-3 card-shadow" >
                <div
                  className="p-3  lightbg"
                  style={{
                    borderTopLeftRadius: 8,
                    borderTopRightRadius: 8,
                    
                  }}
                >
                  <span className=" txt-md fw-bold blacktxt ">Parcours</span>
                </div>
                <div
                  className="p-3 whitebg"
                  style={{
                    
                  }}
                >
                  {/* <span className=' txt-l fw-bold blacktxt ' >Spécialité D'intervention</span> */}
                  {userinfo.parcours.length!==0 ?  (

<div className="row mt-2" style={{}}>
{userinfo.parcours.map((item, index) => (
  <>
            <div className="row mb-2" style={{}}>
        <div className="col-auto my-auto" style={{}}>
        <div
          className="lightbg text-center  d-flex "
          style={{
            width: 40,
            height: 40,
            borderRadius: "100%",
          }}
        >

          <i class="fa-solid fa-graduation-cap mx-auto my-auto primarytxt"></i>
        </div>
        </div>
  <div className="col" style={{}}>
    <div className="row" style={{}}>
      <div className="col-12" style={{}}>
        <span className=" txt-sm   primarytxt ">
          {item.year}
        </span>
      </div>
      <div className="col my-auto" style={{}}>
        <span className=" txt-sm   blacktxt ">
          {item.label}
        </span>
      </div>
    </div>

  </div>
  </div>
  </>
))}
</div>
) : 
                  (

<div className="text-center align-items-center" style={{
    height: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
}}>
    <div>
    
        <p className="primarytxt mt-2 txt-sm"> à compléter</p>
    </div></div>
                  )
}





                
                </div>
              </div>
              {/* card end */}

              {/* card */}
              <div className="mb-3 card-shadow">
                <div
                  className="p-3  lightbg"
                  style={{
                    borderTopLeftRadius: 8,
                    borderTopRightRadius: 8,
                    
                  }}
                >
                  <span className=" txt-md fw-bold blacktxt ">Langues</span>
                </div>
                <div
                  className="p-3 whitebg"
                  style={{
                    
                  }}
                >
                  {/* <span className=' txt-l fw-bold blacktxt ' >Spécialité D'intervention</span> */}


                  {userinfo.languages.length!==0 ?  (

<div className="row mt-2" style={{}}>
{userinfo.languages.map((item, index) => (
  <div className="col-md-4 mb-2 col-sm-6 col-10" style={{}}>
    <div className="row" style={{}}>
      <div className="col-auto" style={{}}>
        <i class="fa-solid fa-earth-americas mx-auto my-auto primarytxt "></i>
      </div>
      <div className="col my-auto" style={{}}>
        <span className=" txt-sm   blacktxt ">
          {item?.name}
        </span>
      </div>
    </div>
  </div>
))}
</div>
) : 
                  (

<div className="text-center align-items-center" style={{
    height: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
}}>
    <div>
    
        <p className="primarytxt mt-2 txt-sm"> à compléter</p>
    </div></div>
                  )
}

                
                </div>
              </div>
              {/* card end */}
            </div>
            <div className="col-md-4 col-sm-10" style={{}}>
              {/* card */}
              <div className="mb-3 card-shadow">
                <div
                  className="p-3  lightbg"
                  style={{
                    borderTopLeftRadius: 8,
                    borderTopRightRadius: 8,
                    
                  }}
                >
                  <span className=" txt-md fw-bold blacktxt ">CEO</span>
                </div>
                <div
                  className="p-3 pe-4 ps-4 mb-4 whitebg"
                  style={{
                    
                  }}
                >
                  {/* <span className=' txt-l fw-bold blacktxt ' >Spécialité D'intervention</span> */}

                  <div className="row">
                    <div className="col-auto">
                      <div
                        className=" "
                        style={{
                          height: 50,
                          borderRadius: "100%",
                          width: 50,
                          background: `url("${
                            userinfo.user.avatar
                              ? BASE_URL + userinfo.user.avatar
                              : images.noimage
                          }")`,
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                        }}
                      ></div>
                    </div>
                    <div className="col my-auto">
                      <span className=" txt-md fw-bold ">
                     
                      {userinfo.user.first_name ? userinfo.user.first_name : "à compléter"} {userinfo.user.last_name ? userinfo.user.last_name : "à compléter"}
                      </span>
                      <br></br>
                      <span className=" txt-xs graytxt ">
                        {" "}
                        {userinfo.category?.name + " - "}
                        {userinfo.ordernumber ? userinfo.ordernumber : "à compléter"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              {/* card end */}

              {/* card */}
              <div className="mb-3 card-shadow">
                <div
                  className="p-3  lightbg"
                  style={{
                    borderTopLeftRadius: 8,
                    borderTopRightRadius: 8,
                    
                  }}
                >
                  <span className=" txt-md fw-bold blacktxt ">
                    Localisation / Contacts
                  </span>
                </div>
                <div
                  className="p-3 pe-4 ps-4 mb-4 whitebg"
                  style={{
                    
                  }}
                >
                  {/* <span className=' txt-l fw-bold blacktxt ' >Spécialité D'intervention</span> */}
                  <div
                    className="row pb-2 pt-2 mb-2"
                    style={{ borderBottom: "1px solid var(--lightgray2)" }}
                  >
                    <div className="col-auto pe-1" style={{}}>
                      <i class="fa-solid fa-location-dot primarytxt  txt-sm"></i>
                    </div>
                    <div className="col-auto" style={{}}>
                      <span className="blacktxt txt-sm" style={{}}>
                  
                        {userinfo.address ? userinfo.address : "à compléter"}
                      </span>
                    </div>
                  </div>

                  <div
                    className="row pb-2 pt-2 mb-2"
                    style={{ borderBottom: "1px solid var(--lightgray2)" }}
                  >
                    <div className="col-auto pe-1" style={{}}>
                      <i class="fa-solid fa-phone primarytxt txt-sm"></i>
                    </div>
                    <div className="col-auto" style={{}}>
                      <span className="blacktxt txt-sm" style={{}}>
                  
                        {userinfo.officenumber ? userinfo.officenumber : "à compléter"}
                      </span>
                    </div>
                  </div>

                  <div className="row pb-2 pt-2 mb-2" style={{}}>
                    <div className="col-auto pe-1" style={{}}>
                      <i class="fa-regular fa-envelope primarytxt txt-sm"></i>
                    </div>
                    <div className="col-auto" style={{}}>
                      <span className="blacktxt txt-sm " style={{}}>
                     
                        {userinfo.officeemail ? userinfo.officeemail : "à compléter"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              {/* card end */}

              {/* card */}
              <div className="mb-3 card-shadow">
                <div
                  className="p-3  lightbg"
                  style={{
                    borderTopLeftRadius: 8,
                    borderTopRightRadius: 8,
                    
                  }}
                >
                  <span className=" txt-md fw-bold blacktxt ">
                    Heures d'ouverture
                  </span>
                </div>
                <div
                  className="p-3 pe-4 ps-4 mb-4 whitebg"
                  style={{
                    
                  }}
                >
                  {/* <span className=' txt-l fw-bold blacktxt ' >Spécialité D'intervention</span> */}
                  <i class="fa-regular fa-clock primarytxt txt-sm me-2"></i>
                  <span className="blacktxt txt-sm">
                  {userinfo.horaires ? userinfo.horaires : "à compléter"}
                  </span>
                </div>
              </div>
              {/* card end */}

                       {/* card */}
              <div className="mb-3 card-shadow">
                <div
                  className="p-3  lightbg"
                  style={{
                    borderTopLeftRadius: 8,
                    borderTopRightRadius: 8,
                    
                  }}
                >
                  <span className=" txt-md fw-bold blacktxt ">
                  Suivez-nous
                  </span>
                </div>
                <div
                  className="p-3 pe-4 ps-4 mb-4 whitebg"
                  style={{
                    
                  }}
                >
                  {/* <span className=' txt-l fw-bold blacktxt ' >Spécialité D'intervention</span> */}
                  {(userinfo.instaid && userinfo.facebookid && userinfo.youtubeid &&  userinfo.linkedinid  && userinfo.pinterestid && userinfo.website)  ? 
                  <>
                  {userinfo.instaid  && 
                  <div
                    className="row pb-2 pt-2 mb-2"
                    style={{ borderBottom: "1px solid var(--lightgray2)" }}
                  >
                    <div className="col-auto pe-1" style={{}}>
                      <i class="fa-brands fa-instagram  primarytxt  txt-sm"></i>
                    </div>
                    <div className="col-auto" style={{}}>
                      <span className="blacktxt txt-sm" style={{}}>
                  
                        {userinfo.instaid ? userinfo.instaid : "à compléter"}
                      </span>
                    </div>
                  </div>}
                  {userinfo.facebookid  && 
                  <div
                    className="row pb-2 pt-2 mb-2"
                    style={{ borderBottom: "1px solid var(--lightgray2)" }}
                  >
                    <div className="col-auto pe-1" style={{}}>
                      <i class="fa-brands fa-facebook primarytxt txt-sm"></i>
                    </div>
                    <div className="col-auto" style={{}}>
                      <span className="blacktxt txt-sm" style={{}}>
                  
                        {userinfo.facebookid ? userinfo.facebookid : "à compléter"}
                      </span>
                    </div>
                    </div>}
                        {userinfo.youtubeid  && 
                  <div
                    className="row pb-2 pt-2 mb-2"
                    style={{ borderBottom: "1px solid var(--lightgray2)" }}
                  >
                    <div className="col-auto pe-1" style={{}}>
                      <i class="fa-brands fa-youtube primarytxt txt-sm"></i>
                    </div>
                    <div className="col-auto" style={{}}>
                      <span className="blacktxt txt-sm" style={{}}>
                  
                        {userinfo.youtubeid ? userinfo.youtubeid : "à compléter"}
                      </span>
                    </div>
                    </div>}
                  {userinfo.linkedinid  && 
                  <div
                    className="row pb-2 pt-2 mb-2"
                    style={{ borderBottom: "1px solid var(--lightgray2)" }}
                  >
                    <div className="col-auto pe-1" style={{}}>
                      <i class="fa-brands fa-linkedin primarytxt txt-sm"></i>
                    </div>
                    <div className="col-auto" style={{}}>
                      <span className="blacktxt txt-sm" style={{}}>
                  
                        {userinfo.linkedinid ? userinfo.linkedinid : "à compléter"}
                      </span>
                    </div>
                    </div>}
                        {userinfo.pinterestid  && 
                  <div
                    className="row pb-2 pt-2 mb-2"
                    style={{ borderBottom: "1px solid var(--lightgray2)" }}
                  >
                    <div className="col-auto pe-1" style={{}}>
                      <i class="fa-brands fa-pinterest primarytxt txt-sm"></i>
                    </div>
                    <div className="col-auto" style={{}}>
                      <span className="blacktxt txt-sm" style={{}}>
                  
                        {userinfo.pinterestid ? userinfo.pinterestid : "à compléter"}
                      </span>
                    </div>
                    </div>}
                        {userinfo.website  && 
                  <div className="row pb-2 pt-2 mb-2" style={{}}>
                    <div className="col-auto pe-1" style={{}}>
                      <i class="fa-solid fa-earth-americas primarytxt txt-sm"></i>
                    </div>
                    <div className="col-auto" style={{}}>
                      <span className="blacktxt txt-sm " style={{}}>
                     
                        {userinfo.website ? userinfo.website : "à compléter"}
                      </span>
                    </div>
                    </div>}
</>
:
<span className="blacktxt txt-sm text-center  " style={{}}>
                     
{userinfo.website ? userinfo.website : "à compléter"}
</span>
}
                  
                </div>
              </div>
              {/* card end */}

                   {/* card */}
              {/* <div className="mb-3 card-shadow">
                <div
                  className="p-3  lightbg"
                  style={{
                    borderTopLeftRadius: 8,
                    borderTopRightRadius: 8,
                    
                  }}
                >
                  <span className=" txt-md fw-bold blacktxt ">
                    Tags
                  </span>
                </div>
                <div
                  className="p-3 pe-4 ps-4 mb-4 whitebg"
                  style={{
                    
                  }}
                >
                  <span className=' txt-l fw-bold blacktxt ' >Spécialité D'intervention</span> 

                  <span className="blacktxt txt-sm">{userinfo.horaires}</span>
                </div>
              </div> */}
              {/* card end */}

            </div>
          </div>
        </div>
      </div>

    
      </div>
      ) : null}
{loadingError === true ? (
  <span>Error</span>
) : null}

{loading === true ? (
  <div
    className="container-fluid d-flex bg-white p-0 m-0"
    style={{ minHeight: "100vh" }}
  >
    <div className='mx-auto my-auto'>
      <img src={images.spinner} style={{ width: 50, height: 50 }} />
    </div>
  </div>
) : null}
     </>
  
  );
};

export default Index;
