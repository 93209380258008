import React from 'react'
import images from '../../config/images'

export default function index() {
  return (
    <div className="row mt-5  ">
    <div className="col-12 p-0 pt-5 position-relative">
      <div className="whitebg pt-5">
        <div
          className="d-flex h-100 w-100 radius4 how-bg  "
          style={{
            background: `url("${images.footerbg}")`,
            
            backgroundPosition: "center",
            position: "relative"
          }}
        >
          <div className="  primarybg-opacity h-100 w-100  pt-5 pb-5">
            <div className="container pt-5 pb-5">
              <div className="col-md-6 col-12  ms-auto ">
                <div className="text-center">
                  <span className=" whitetxt txt-sm  ">Téléchargez</span>
                  <br />
                  <span className=" fw-bold whitetxt txt-xl ">
                    L'app Mobile Booking Architecture
                  </span>
                  <br />
                </div>
                <div className=" mt-5">
                  <div className="row ">
                    <div className="col-sm-6 col-12 mb-3   ">
                      <div
                        className="download raduis4 w-100 download-button pt-2 pb-2 me-auto ms-auto  "
                        style={{ maxWidth: 200 }}
                      >
                        <div className="row">
                          <div className="col-auto mt-auto mb-auto text-center">
                            <i className=" txt-xl whitetxt fa-brands fa-apple ms-3" />
                          </div>
                          <div className="col-auto">
                            <div>
                              <span className=" fw-bold whitetxt txt-sm ">
                                Download on the
                              </span>
                              <br />
                              <span className=" fw-bold whitetxt txt-md ">
                                Apple Store
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-12 mb-3  ">
                      <div
                        className="download raduis4 w-100 download-button pt-2 pb-2  me-auto ms-auto"
                        style={{ maxWidth: 200 }}
                      >
                        <a href='https://play.google.com/store/apps/details?id=com.anonymous.Architecte' className=' text-decoration-none' >
                        <div className="row">
                          <div className="col-auto mt-auto mb-auto text-center">
                            <i className=" txt-xl whitetxt fa-brands fa-google-play ms-3" />
                          </div>
                          <div className="col-auto">
                            <div>
                              <span className=" fw-bold whitetxt txt-sm ">
                                Download on the
                              </span>
                              <br />
                              <span className=" fw-bold whitetxt txt-md ">
                                Google Play
                              </span>
                            </div>
                          </div>
                        </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img
        style={{}}
        className="img-fluid phones  phones-animation "
        src={images.phones}
      />
    </div>
  </div>
  )
}
