import { BASE_URL } from "../../config/constants/apiEndpoints";
import { useDateFormat } from "../../config/hooks/general";
import images from "../../config/images";


export const Events = ({ item }) => {
    const dateString = item.created_at;
    const formatDate = useDateFormat(dateString);
    return(
        <div
        className="row m-0 p-0  mb-3 w-100 position-relative "
        style={{
          minHeight: "200px",
          width: "100%",
          background: `url("${
            item.image
              ? BASE_URL + item.image
              : images.noimage
          }")`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div
          className=" d-flex position-absolute w-100 h-100 "
          style={{
            background: "linear-gradient(to bottom, transparent, black)",
          }}
        >
        <div className="mt-auto mb-2 ">
          <span className="whitetxt txt-sm mb-1" >{formatDate}</span><br></br>
          <span className=" whitetxt txt-md fw-bold " >{item.name}</span>
          </div>
        </div>
        </div>
    )
}