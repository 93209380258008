import React from "react";
import { Provider } from "react-redux";
import { store, persistor } from './config/redux/index';
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Profile from './pages/Profile';
import Event from './pages/Event';
import Home from './pages/Home';
import Dashboard from './pages/Dashboard';
import Listing from './pages/Listing';
import Test from './pages/test';
import Privacy from './pages/privacy';
import Categories from './pages/Dashboard/Categories';
import Login from './pages/Dashboard/Login';
import Events from './pages/Dashboard/Events'
import Covers from './pages/Dashboard/Covers';
import Pubs from './pages/Dashboard/Pubs';
import Profiles from './pages/Dashboard/Profiles';
import Users from './pages/Dashboard/users';
import UsersByCategory from './pages/Dashboard/usersbycategory';
import ProfilesReports from './pages/Dashboard/ProfilesReports';
import ProfilesRequest from './pages/Dashboard/ProfilesRequest';
import PostsReports from './pages/Dashboard/PostsReports';
import CommentsReports from './pages/Dashboard/CommentsReports';
import Dash from './pages/Dashboard/Dash';
//... other imports ...
import ProtectedRoute from './ProtectedRoute'; 
const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
         <Routes>
         <Route  path="/profile/:id" element={<Profile/>}/>
         <Route  path="/event/:id" element={<Event/>}/>
      <Route  path="/" element={<Home/>}/>
      <Route  path="/privacy" element={<Privacy/>}/>
      <Route  path="/test" element={<Test/>}/>
      <Route  path="/listing" element={<Listing/>}/>
       <Route  path="/login" element={<ProtectedRoute component={Login} />} />

       <Route path="/dashboard" element={<ProtectedRoute component={Dash} />} />
   <Route path="/dashboard/categories" element={<ProtectedRoute component={Categories} />} />
   <Route path="/dashboard/events" element={<ProtectedRoute component={Events} />} />
   <Route path="/dashboard/banners" element={<ProtectedRoute component={Covers} />} />
   <Route path="/dashboard/pubs" element={<ProtectedRoute component={Pubs} />} />
   <Route path="/dashboard/profiles" element={<ProtectedRoute component={Profiles} />} />
   <Route path="/dashboard/users" element={<ProtectedRoute component={Users} />} />
   <Route path="/dashboard/users/category/:id" element={<ProtectedRoute component={UsersByCategory} />} />
   <Route path="/dashboard/profilesreports" element={<ProtectedRoute component={ProfilesReports} />} />
   <Route path="/dashboard/profilesrequests" element={<ProtectedRoute component={ProfilesRequest} />} />
   <Route path="/dashboard/postsreports" element={<ProtectedRoute component={PostsReports} />} />
   <Route path="/dashboard/commentsreports" element={<ProtectedRoute component={CommentsReports} />} />

    </Routes>
        </Router>
      </PersistGate>
    </Provider>
  );
};

export default App;