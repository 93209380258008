import React, { useState, useEffect } from 'react';
import images from '../../config/images'
import './Sidebar.css';
import { SidebarElements } from './components';

const Sidebar = () => {
  const [isCollapsed, setIsCollapsed] = useState(window.innerWidth < 1000);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const toggleSidebar = () => {
    if (window.innerWidth < 1000) {
      setIsFullScreen(!isFullScreen);
    } else {
      setIsCollapsed(!isCollapsed);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsFullScreen(false);
      setIsCollapsed(window.innerWidth < 1000);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className={`sidebar ${isFullScreen ? 'fullscreen' : ''} ${isCollapsed ? 'collapsed' : ''}`}>
      <div className=' position-relative ' >
      <button onClick={toggleSidebar} className="toggle-btn border-0 bg-none ">
        <i class={"fa-solid whitetxt txt-xs "+(isCollapsed || isFullScreen  ? 'fa-bars' : 'fa-x')} style={{color:"#fff"}} ></i>
      </button>
      </div>
     
      {(!isCollapsed || isFullScreen) && <div className="sidebar-content">
      <div className="d-flex">
        <img src={images.logo_white} style={{width:130,height:'auto'}} className='me-auto ms-auto mb-5'  />
        </div>
          <SidebarElements/>
        </div>}
      <style>
        {`
     .sidebar {
      min-height: 100vh;
      height: 100%;
      width: ${isFullScreen ? '100vw' : (isCollapsed ? '0px' : '240px')};
      position: sticky;
      top: 0;
      background-color: #1E1E2D;
      transition: width 0.3s;
      z-index: 999;
  }
  
        .toggle-btn {
          position: absolute;
          right: ${isFullScreen ? '10' : (isCollapsed ? '-50px' : '-25px')};
          background-color: #1E1E2D;
          border-radius: 100%;
          height: 30px;
          width: 30px;
          box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
      }
        .toggle-btn {
          margin: 15px;
        }
        .sidebar-content {
          padding: 20px;
        }
        .fullscreen {
          width: 100vw;
        }
        `}
      </style>
    </div>
  );
};

export default Sidebar;
