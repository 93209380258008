import images from "../../../config/images";
import React, { useEffect, useState } from "react";
import "./style.css";
import apiMethods from "../../../config/services/ApiService";
import { BASE_URL } from "../../../config/constants/apiEndpoints";
import { CustomModal } from "../../../components/modal";
import Dashboard from "../index";
import { connect, useDispatch } from "react-redux";
import { login } from "../../../config/redux/actions/auth";
import { Link, useNavigate } from 'react-router-dom';
const Index = ({token,roles}) => {
    const [username, setUsername] = useState('');
const [password, setPassword] = useState('');
const [message, setMessage] = useState("");
const [loading, setLoading] = useState(false);
const [buttonLoading, setButtonLoading] = useState(false);
const [error, setError] = useState(false);
const dispatch = useDispatch();
const navigate = useNavigate();
useEffect(() => {
    if (token ) {
        if(roles !== null){
        if(roles[0] == "ROLE_ADMIN"){
            navigate('/dashboard');
        }
    }
    }
  }, [token, roles, navigate]);


const handleSubmit = async (e) => {
    setButtonLoading(true);
    e.preventDefault();
    if (username != "" || password != "") {
      setLoading(true);
      dispatch(login(username, password, handleLoginCallback));
    } else {
      setMessage("Tous les champs sont importants");
      console.log("Tous les champs sont importants");
    }
  };

  const handleLoginCallback = async (data) => {
    setButtonLoading(false);
    setLoading(false);
    if (data?.token) {
      console.log("welcome ");
      if (data?.roles[0]=="ROLE_ADMIN") {
        console.log("yes ");
        navigate("/dashboard"); 
      }else{
        console.log("no ");
      }

    } else {
      setMessage("Login f faileddddd!");
      console.log("Login f faileddddd! ");
      setError(true);
    }
  };
  console.log("the roles is:", JSON.stringify(roles));
    return(
        <>
        <div className=" container-fluid    " style={{minHeight:'100vh',height:'100%'}} >
        <div className=" row     "  style={{height:'100vh'}} >
        <div className="col-12 col-lg-12 h-100  d-flex "  >
        <div className="login-form my-auto mx-auto  whitebg p-5  " style={{width:450,borderRadius:8,boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"}}  >
            {/* Welcome message */}
            <div className="d-flex mb-2 " style={{width:'100%'}}  >
            <img src={images.logo_fullblack} className="mx-auto" style={{width:130}} />
          
            </div>
   
           <h4 className="blacktxt text-center" >Connexion</h4>
            <p className="blacktxt text-center">Utiliser votre compte Admin</p>
  

              {/* Login form */}
              <form onSubmit={handleSubmit} className="mb-3">
                <div className="form-group mb-3">
                  <label htmlFor="username ">Nom d'utilisateur:</label>
                  <input 
    type="text" 
    className="form-control a-input mt-1" 
    id="username" 
    placeholder="Saisissez votre nom d'utilisateur"
    value={username}
    onChange={(e) => setUsername(e.target.value)} 
/>
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="password">Mot de passe:</label>
                  <input 
    type="password" 
    className="form-control a-input mt-1" 
    id="password" 
    placeholder="Entrer le mot de passe"
    value={password}
    onChange={(e) => setPassword(e.target.value)} 
/>
                </div>
                <div className="form-group mb-3">
                <input 
    type="checkbox" 
/><span className="blacktxt ms-2" >Souviens-toi de moi sur cet appareil</span>
                    </div>
                <div className="form-group">
                  <button type="submit" className="a-btn primary whitetxt w-100">

                  {!buttonLoading ? (
                  "Se Connecter"
                ) : (
                  <img
                    className="mx-auto my-auto"
                    src={images.spinner}
                    style={{ width: 20 }}
                  />
                )}
                  
                  </button>
                </div>
              </form>
              <Link to={"/"} className=" text-decoration-none" >
              <i class="fa-solid fa-chevron-left blacktxt txt-sm me-2"></i><span className="blacktxt" >Retourner</span>
              </Link>
            </div>
        </div>
        {/* <div className="col-12 col-lg-8 h-100  archloginbg"  >
        
        </div> */}
        </div>
        </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
      token: state.user.token,
      roles: state.user.roles,
    };
  };
  
  export default connect(mapStateToProps)(Index);