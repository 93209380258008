import React, { useState } from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root');

export const CustomModal = ({ isOpen, onRequestClose, contentLabel, children }) => {
  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      // Add more styling here
    },
  };
  
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      contentLabel={contentLabel}
    >
        <div className="p-3" >
      {children}
      </div>
    </Modal>
  );
};
