import React from 'react'

const Privacy = () => {
  return (
    <div>
        <p>Nous accordons une grande importance à la protection de votre vie
            privée et à la sécurité de vos données. Notre Politique de
            Confidentialité ci-dessous détaille comment nous collectons,
            utilisons, divulguons et protégeons vos informations personnelles
            lorsque vous utilisez notre Application de Réservation
            d'Architectes.</p>
            <p>
            Nous collectons des informations personnelles telles que votre nom,
            votre adresse e-mail, votre numéro de téléphone et votre adresse
            lors de la création d'un compte utilisateur. Les informations de
            paiement, y compris les détails de carte de crédit, sont collectées
            exclusivement pour le traitement des réservations et sont stockées
            de manière sécurisée. Les données de réservation et d'utilisation
            sont utilisées pour personnaliser votre expérience. Nous utilisons
            des cookies pour améliorer la navigation. Vos données sont protégées
            par des mesures de sécurité appropriées, et vous pouvez accéder,
            mettre à jour ou supprimer vos informations via votre compte
            utilisateur. Nous nous réservons le droit de modifier cette
            Politique de Confidentialité, et votre utilisation continue de
            l'application constitue l'acceptation de ces termes. Pour toute
            question, veuillez nous contacter à bookingarchitecturema@gmail.com.
            </p>
    </div>
  )
}
export default Privacy;

