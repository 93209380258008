
import {Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { logout } from "../../config/redux/actions/auth";
import { useNavigate } from 'react-router-dom';
export const SidebarElements = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogoutCallback = async () => {
    navigate('/login');
    console.log("log out");
  };
  const handleLogout = async () => {
    dispatch(logout(handleLogoutCallback));
    navigate('/login');
    console.log("log out");
  };
    return (
      <>
           {/* <Link to={"/dashboard"} className=" text-decoration-none" >
        <div className="d-flex sidebar-elements mb-3" style={{}}>
          <span class="svg-icon svg-icon-2">
            <svg
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="2"
                y="2"
                width="9"
                height="9"
                rx="2"
                fill="currentColor"
              ></rect>
              <rect
                opacity="0.3"
                x="13"
                y="2"
                width="9"
                height="9"
                rx="2"
                fill="currentColor"
              ></rect>
              <rect
                opacity="0.3"
                x="13"
                y="13"
                width="9"
                height="9"
                rx="2"
                fill="currentColor"
              ></rect>
              <rect
                opacity="0.3"
                x="2"
                y="13"
                width="9"
                height="9"
                rx="2"
                fill="currentColor"
              ></rect>
            </svg>
          </span>
          <span className=" element txt-sm my-auto ms-2">Dashboard</span>
          <i class="fa-solid fa-chevron-right txt-xxs my-auto  ms-auto "></i>
        </div>
        </Link> */}
        <div class="sidebar-menu-title mb-3">
          <span class="txt-sm">App</span>
        </div>
          <Link to={"/dashboard/categories"} className=" text-decoration-none" >
        <div className="d-flex sidebar-elements mb-3" style={{}}>
          <span class="svg-icon svg-icon-2">
            <svg
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.3"
                d="M21.25 18.525L13.05 21.825C12.35 22.125 11.65 22.125 10.95 21.825L2.75 18.525C1.75 18.125 1.75 16.725 2.75 16.325L4.04999 15.825L10.25 18.325C10.85 18.525 11.45 18.625 12.05 18.625C12.65 18.625 13.25 18.525 13.85 18.325L20.05 15.825L21.35 16.325C22.35 16.725 22.35 18.125 21.25 18.525ZM13.05 16.425L21.25 13.125C22.25 12.725 22.25 11.325 21.25 10.925L13.05 7.62502C12.35 7.32502 11.65 7.32502 10.95 7.62502L2.75 10.925C1.75 11.325 1.75 12.725 2.75 13.125L10.95 16.425C11.65 16.725 12.45 16.725 13.05 16.425Z"
                fill="currentColor"
              ></path>
              <path
                d="M11.05 11.025L2.84998 7.725C1.84998 7.325 1.84998 5.925 2.84998 5.525L11.05 2.225C11.75 1.925 12.45 1.925 13.15 2.225L21.35 5.525C22.35 5.925 22.35 7.325 21.35 7.725L13.05 11.025C12.45 11.325 11.65 11.325 11.05 11.025Z"
                fill="currentColor"
              ></path>
            </svg>
          </span>
          <span className=" element txt-sm my-auto  ms-2">Categories</span>
          <i class="fa-solid fa-chevron-right txt-xxs my-auto  ms-auto "></i>
          </div>
          </Link>
      
          <Link to={"/dashboard/events"} className=" text-decoration-none" >
        <div className="d-flex sidebar-elements mb-3" style={{}}>
          <span class="svg-icon svg-icon-2">
            <svg
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20 4H4C2.9 4 2 4.9 2 6V20C2 21.1 2.9 22 4 22H20C21.1 22 22 21.1 22 20V6C22 4.9 21.1 4 20 4ZM20 20H4V9H20V20ZM6 3V5H5V6H6V7H8V6H9V5H8V3H6ZM18 3V5H17V6H18V7H20V6H21V5H20V3H18Z"
                fill="currentColor"
              ></path>
              <path
                d="M6 11H8V13H6V11ZM10 11H12V13H10V11ZM14 11H16V13H14V11ZM6 15H8V17H6V15ZM10 15H12V17H10V15ZM14 15H16V17H14V15Z"
                fill="currentColor"
                opacity="0.3"
              ></path>
            </svg>
          </span>
  
          <span className=" element txt-sm my-auto ms-2">Events</span>
          <i class="fa-solid fa-chevron-right txt-xxs my-auto  ms-auto "></i>
        </div>
        </Link>
        <Link to={"/dashboard/banners"} className=" text-decoration-none" >
        <div className="d-flex sidebar-elements mb-3" style={{}}>
          <span class="svg-icon svg-icon-2">
            <svg
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21 3H3C1.9 3 1 3.9 1 5V19C1 20.1 1.9 21 3 21H21C22.1 21 23 20.1 23 19V5C23 3.9 22.1 3 21 3ZM21 19H3V5H21V19Z"
                fill="currentColor"
              ></path>
              <path
                d="M5 6C4.4 6 4 6.4 4 7C4 7.6 4.4 8 5 8C5.6 8 6 7.6 6 7C6 6.4 5.6 6 5 6ZM9 17L6 13L13 8L16 12L20 7L21 10L16 15L13 12L9 17Z"
                fill="currentColor"
                opacity="0.3"
              ></path>
            </svg>
          </span>
  
          <span className=" element txt-sm my-auto  ms-2">Bannières D'accueil</span>
          <i class="fa-solid fa-chevron-right txt-xxs my-auto  ms-auto "></i>
        </div>
        </Link>
        <Link to={"/dashboard/pubs"} className=" text-decoration-none" >
        <div className="d-flex sidebar-elements mb-3" style={{}}>
          <span class="svg-icon svg-icon-2">
            <svg
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21 3H3C1.9 3 1 3.9 1 5V19C1 20.1 1.9 21 3 21H21C22.1 21 23 20.1 23 19V5C23 3.9 22.1 3 21 3ZM21 19H3V5H21V19Z"
                fill="currentColor"
              ></path>
              <path
                d="M5 6C4.4 6 4 6.4 4 7C4 7.6 4.4 8 5 8C5.6 8 6 7.6 6 7C6 6.4 5.6 6 5 6ZM9 17L6 13L13 8L16 12L20 7L21 10L16 15L13 12L9 17Z"
                fill="currentColor"
                opacity="0.3"
              ></path>
            </svg>
          </span>
  
          <span className=" element txt-sm my-auto  ms-2">Bannières Pub</span>
          <i class="fa-solid fa-chevron-right txt-xxs my-auto  ms-auto "></i>
        </div>
        </Link>
        <div class="sidebar-menu-title mb-3">
          <span class="txt-sm">Abonnés</span>
        </div>
        <Link to={"/dashboard/profiles"} className=" text-decoration-none" >
        <div className=" d-flex sidebar-elements mb-3" style={{}}>
          <span class="svg-icon svg-icon-2">
            <svg
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20 14H18V10H20C20.6 10 21 10.4 21 11V13C21 13.6 20.6 14 20 14ZM21 19V17C21 16.4 20.6 16 20 16H18V20H20C20.6 20 21 19.6 21 19ZM21 7V5C21 4.4 20.6 4 20 4H18V8H20C20.6 8 21 7.6 21 7Z"
                fill="currentColor"
              ></path>
              <path
                opacity="0.3"
                d="M17 22H3C2.4 22 2 21.6 2 21V3C2 2.4 2.4 2 3 2H17C17.6 2 18 2.4 18 3V21C18 21.6 17.6 22 17 22ZM10 7C8.9 7 8 7.9 8 9C8 10.1 8.9 11 10 11C11.1 11 12 10.1 12 9C12 7.9 11.1 7 10 7ZM13.3 16C14 16 14.5 15.3 14.3 14.7C13.7 13.2 12 12 10.1 12C8.10001 12 6.49999 13.1 5.89999 14.7C5.59999 15.3 6.19999 16 7.39999 16H13.3Z"
                fill="currentColor"
              ></path>
            </svg>
          </span>
          <span className=" element txt-sm  my-auto  ms-2">Profiles</span>
          <i class="fa-solid fa-chevron-right txt-xxs my-auto  ms-auto "></i>
        </div>
        </Link>
        <Link to={"/dashboard/profilesrequests"} className=" text-decoration-none" >
        <div className=" d-flex sidebar-elements mb-3" style={{}}>
          <span class="svg-icon svg-icon-2">
            <svg
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20 14H18V10H20C20.6 10 21 10.4 21 11V13C21 13.6 20.6 14 20 14ZM21 19V17C21 16.4 20.6 16 20 16H18V20H20C20.6 20 21 19.6 21 19ZM21 7V5C21 4.4 20.6 4 20 4H18V8H20C20.6 8 21 7.6 21 7Z"
                fill="currentColor"
              ></path>
              <path
                opacity="0.3"
                d="M17 22H3C2.4 22 2 21.6 2 21V3C2 2.4 2.4 2 3 2H17C17.6 2 18 2.4 18 3V21C18 21.6 17.6 22 17 22ZM10 7C8.9 7 8 7.9 8 9C8 10.1 8.9 11 10 11C11.1 11 12 10.1 12 9C12 7.9 11.1 7 10 7ZM13.3 16C14 16 14.5 15.3 14.3 14.7C13.7 13.2 12 12 10.1 12C8.10001 12 6.49999 13.1 5.89999 14.7C5.59999 15.3 6.19999 16 7.39999 16H13.3Z"
                fill="currentColor"
              ></path>
            </svg>
          </span>
          <span className=" element txt-sm  my-auto  ms-2">Profiles Requests</span>
          <i class="fa-solid fa-chevron-right txt-xxs my-auto  ms-auto "></i>
        </div>
        </Link>
        <Link to={"/dashboard/users"} className=" text-decoration-none" >
        <div className=" d-flex sidebar-elements mb-3" style={{}}>
          <span class="svg-icon svg-icon-2">
            <svg
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.28548 15.0861C7.34369 13.1814 9.35142 12 11.5304 12H12.4696C14.6486 12 16.6563 13.1814 17.7145 15.0861L19.3493 18.0287C20.0899 19.3618 19.1259 21 17.601 21H6.39903C4.87406 21 3.91012 19.3618 4.65071 18.0287L6.28548 15.0861Z"
                fill="currentColor"
              ></path>
              <rect
                opacity="0.3"
                x="8"
                y="3"
                width="8"
                height="8"
                rx="4"
                fill="currentColor"
              ></rect>
            </svg>
          </span>
          <span className=" element txt-sm my-auto ms-2">Users</span>
          <i class="fa-solid fa-chevron-right txt-xxs my-auto  ms-auto "></i>
        </div>
        </Link>

        <div class="sidebar-menu-title mb-3">
          <span class="txt-sm">Reports</span>
        </div>
        <Link to={"/dashboard/profilesreports"} className=" text-decoration-none" >
        <div className="d-flex sidebar-elements mb-3" style={{}}>
          <span class="svg-icon svg-icon-2">
            <svg
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z"
                fill="currentColor"
              ></path>
              <path
                d="M11 6H13V12H11V6ZM11 14H13V16H11V14Z"
                fill="currentColor"
                opacity="0.3"
              ></path>
            </svg>
          </span>
  
          <span className=" element txt-sm my-auto  ms-2">Profiles Reports</span>
          <i class="fa-solid fa-chevron-right txt-xxs my-auto  ms-auto "></i>
        </div>
        </Link>
        <Link to={"/dashboard/postsreports"} className=" text-decoration-none" >
        <div className="d-flex sidebar-elements mb-3" style={{}}>
          <span class="svg-icon svg-icon-2">
            <svg
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 2H6C4.9 2 4 2.9 4 4V20C4 21.1 4.9 22 6 22H18C19.1 22 20 21.1 20 20V4C20 2.9 19.1 2 18 2ZM18 20H6V4H18V20Z"
                fill="currentColor"
              ></path>
              <path
                d="M10 6H14V8H10V6ZM10 10H14V12H10V10ZM10 14H14V16H10V14Z"
                fill="currentColor"
                opacity="0.3"
              ></path>
            </svg>
          </span>
  
          <span className=" element txt-sm  ms-2">Posts Reports</span>
          <i class="fa-solid fa-chevron-right txt-xxs my-auto  ms-auto "></i>
        </div>
        </Link>
        <Link to={"/dashboard/commentsreports"} className=" text-decoration-none" >
        <div className="d-flex sidebar-elements mb-3" style={{}}>
          <span class="svg-icon svg-icon-2">
            <svg
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4 4H20V16H4V4ZM20 2H4C2.9 2 2 2.9 2 4V16C2 17.1 2.9 18 4 18H18L22 22V4C22 2.9 21.1 2 20 2Z"
                fill="currentColor"
              ></path>
              <path
                d="M6 6H18V8H6V6ZM6 10H12V12H6V10Z"
                fill="currentColor"
                opacity="0.3"
              ></path>
            </svg>
          </span>
          <span className=" element txt-sm  ms-2">Comments Reports</span>
          <i class="fa-solid fa-chevron-right txt-xxs my-auto  ms-auto "></i>
        </div>
        </Link>
        <div class="sidebar-menu-title mb-3">
          <span class="txt-sm">Admin</span>
        </div>
        <Link to={"/"} className=" text-decoration-none" >
        <div className="d-flex sidebar-elements  mb-3" style={{}}>
          <span class="svg-icon svg-icon-2">
          <svg
    width="18"
    height="18"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>

    <path
        d="M12 2L2 12H5V20H11V14H13V20H19V12H22L12 2Z"
        fill="currentColor"
    ></path>

    <path
        opacity="0.3"
        d="M6 16V10H10V16H6Z"
        fill="currentColor"
    ></path>
</svg>


          </span>
          <span className=" element txt-sm  ms-2">Home</span>
          <i class="fa-solid fa-chevron-right txt-xxs my-auto  ms-auto "></i>
        </div>
        </Link>
        <button className="btn-0" onClick={handleLogout} >
              <div className="d-flex sidebar-elements  mb-3" style={{}}>
          <span class="svg-icon svg-icon-2">
          <svg
    width="18"
    height="18"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
  
    <path
        d="M15,11 L20,11 L20,13 L15,13 L18,16 L16.5,16 L12,12.5 L16.5,9 L18,9 L15,12 L15,11 Z"
        fill="currentColor"
    ></path>


    <path
        opacity="0.3"
        d="M6 4H12V18H6V4ZM6 2H12C13.1 2 14 2.9 14 4V18C14 19.1 13.1 20 12 20H6C4.9 20 4 19.1 4 18V4C4 2.9 4.9 2 6 2Z"
        fill="currentColor"
    ></path>
</svg>


          </span>
          <span className=" element txt-sm  ms-2">Log Out</span>
          
        </div>
        </button>
    
      </>
    );
  };

