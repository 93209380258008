import React from 'react'
import images from '../../config/images'
import { Link } from 'react-router-dom'

export default function index() {
  return (
    <div className="container-fluid p-0 m-0">
    <div className="row blackbg  ">
    <div className="container pt-5 pb-5 ">
      <div className="row">
        <div className="col-12 col-md-6 col-lg-3 mb-3 ">
          <img src={images.logo} style={{ width: 150 }} />
          <br />
          <br />
          <span className=" graytxt txt-xs ">
          La  nouvelle  application de recherche des professionels liés à l'architecture </span>
          <br />
          <br />

        </div>
        <div className="col-12 col-md-6 col-lg-3  align-content-between  mb-3 ">
          <p className=" fw-bold whitetxt txt-lg ">Catégories</p>
          <br />
          <Link to={"/listing"} className="text-decoration-none primarytxt">
          <p className=" graytxt txt-md ">Architectes</p>
          </Link>
          <Link to={"/listing"} className="text-decoration-none primarytxt">
          <p className=" graytxt txt-md ">Ingénieurs</p>
          </Link>
          <Link to={"/listing"} className="text-decoration-none primarytxt">
          <p className=" graytxt txt-md ">Géomètres</p>
          </Link>
          <Link to={"/listing"} className="text-decoration-none primarytxt">
          <p className=" graytxt txt-md ">Sociétés BTP</p>
          </Link>
          <Link to={"/listing"} className="text-decoration-none primarytxt">
          <p className=" graytxt txt-md ">Décoration, Ameublement</p>
          </Link>
        </div>
        <div className="col-12 col-md-6 col-lg-3  align-content-between mb-3  ">
          <p className=" fw-bold whitetxt txt-lg ">Lien Rapides</p>
          <br />
          <a href="#who" className="text-decoration-none" >
          <p className=" graytxt txt-md ">A propos</p>
          </a>
          <a href="#header" className="text-decoration-none" >
          <p className=" graytxt txt-md ">Contact</p>
          </a>
          {/* <p className=" graytxt txt-md ">Privacy Policy</p>
          <p className=" graytxt txt-md ">FAQ</p> */}
        </div>
        <div className="col-12 col-md-6 col-lg-3  align-content-between   mb-3 ">
          <p className=" fw-bold whitetxt txt-lg ">Contact</p>
          <br />
          {/* <div className="row">
            <div className="col-auto ps-2 p-0">
              <input
                className="p-2 ps-3 w-auto  newsletter-input border-radius-left-4 border-0 graytxt txt-sm fw-bold "
                placeholder="Votre email..."
              />
            </div>
            <div className="col p-0">
              <button className=" border-0 h-100 secondarybg pe-3 ps-3  border-radius-right-4">
                <span className=" txt-sm whitetxt ">S'inscrire</span>
              </button>
            </div>
          </div> */}
                    <div className="row mb-2">
            <div className="col-auto">
              <i className="fa-solid fa-phone primarytxt txt-md " />
            </div>
            <div className="col">
              <span className=" whitetxt txt-md ">+212 644 872 062</span>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-auto">
              <i className="fa-solid fa-fax primarytxt txt-md" />
            </div>
            <div className="col">
              <span className=" whitetxt txt-md ">+212 644 872 062</span>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-auto">
              <i className="fa-regular fa-envelope-open primarytxt txt-md" />
            </div>
            <div className="col">
              <span className=" whitetxt txt-xs ">contact@bookingarchitecture.com</span>
            </div>
          </div>
          <div className="row mt-5">
            <p className=" fw-bold primarytxt txt-sm ">Suivez-nous !</p>
            <div className="row">
              <div className="col-auto">
                <a href="https://web.facebook.com/booking.architectureMA/" className=" text-decoration-none">
                  <i className="fa-brands fa-facebook graytxt " />
                </a>
              </div>
              <div className="col-auto">
                <a href="https://instagram.com/booking.architecture" className=" text-decoration-none">
                  <i className="fa-brands fa-instagram graytxt" />
                </a>
              </div>
              {/* <div className="col-auto">
                <a href="" className=" text-decoration-none">
                  <i className="fa-brands fa-linkedin graytxt" />
                </a>
              </div> */}
              <div className="col-auto">
                <a href="https://www.youtube.com/channel/UCt2J_4N3Mz7RX8EeA9xOApA" className=" text-decoration-none">
                  <i className="fa-brands fa-youtube graytxt" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  )
}
