
import axios from "axios";
import { store } from "../redux/index";
import {
API_BASE_URL,
CATEGORY_ENDPOINT,
EVENTS_ENDPOINT,
LOGIN_CHECK_ENDPOINT,
COVERS_ENDPOINT,
REFERENCES_ENDPOINT,
INTERVENTION_SPECIALTIES_ENDPOINT,
LANGUAGES_ENDPOINT,
PARCOURS_ENDPOINT,
UPDATE_PROFILE_ENDPOINT,
ARCHITECT_PROFILES_ENDPOINT,
MEDIA_ENDPOINT,
COMMENTS_ENDPOINT,
FEEDBACK_ENDPOINT,
UPDATE_USER_ENDPOINT,
CATEGORY_BY_PROFILE_ENDPOINT,
POSTS_ENDPOINT,
FEEDBACKS_ENDPOINT,
ARCHITECT_BY_PROFILES_ENDPOINT,
REPLIES_ENDPOINT,
BULK_ENDPOINT,
USERS_ENDPOINT,
BANNERS_ENDPOINT,PROFILES_REPORTS_ENDPOINT,POSTS_REPORTS_ENDPOINT,COMMENTS_REPORTS_ENDPOINT,PROFILEREQUEST_ENDPOINT, BANNERSP_ENDPOINT, USERBYCATEGORY_ENDPOINT,
} from "../constants/apiEndpoints";
const nojwtapi = axios.create({
baseURL: API_BASE_URL,
});
const api = axios.create({
baseURL: API_BASE_URL,
});
const apiMedia = axios.create({
baseURL: API_BASE_URL,
});
const apiPatch = axios.create({
baseURL: API_BASE_URL,
});
api.interceptors.request.use(
async (config) => {
const state = store.getState();
const accessToken = state.user.token;
if (accessToken) {
config.headers["Authorization"] = `Bearer ${accessToken}`;
}
return config;
},
(error) => {
console.error("Error adding access token to request:");
}
);
apiPatch.interceptors.request.use(
async (config) => {
const state = store.getState();
const accessToken = state.user.token;
if (accessToken) {
config.headers["Authorization"] = `Bearer ${accessToken}`;
}
config.headers["Content-Type"] = "application/merge-patch+json";
return config;
},
(error) => {
console.error("Error adding access token to request:");
}
);
apiMedia.interceptors.request.use(
async (config) => {
const state = store.getState();
const accessToken = state.user.token;
if (accessToken) {
config.headers["Authorization"] = `Bearer ${accessToken}`;
}
if (config.data instanceof FormData) {
config.headers["Content-Type"] = "multipart/form-data";
}
return config;
},
(error) => {
console.error("Error adding access token to request:");
}
);
api.interceptors.response.use(
(response) => response,
async (error) => {
const originalRequest = error.config;
if (
error.response &&
error.response.status === 401 &&
!originalRequest._retry
) {
originalRequest._retry = true;
try {
const state = store.getState();
// const accessToken = state.user.token.token;
// Retry the original request with the new token
// originalRequest.headers["Authorization"] = `Token ${accessToken}`;
// return api(originalRequest);
} catch (error) {
console.error("Error refreshing access token");
}
}
}
);
const apiMethods = {
//------Category (AnasDigital comments)
getCategories: (name="") => nojwtapi.get(`${CATEGORY_ENDPOINT}?name=${name}`),
postCategories: (data) => api.post(`${CATEGORY_ENDPOINT}`,data),
deleteCategories: (id) => api.delete(`${CATEGORY_ENDPOINT}/${id}`),
updateCategories: (id,data) => apiPatch.patch(`${CATEGORY_ENDPOINT}/${id}`,data),
//------Events (AnasDigital comments)
getEvents: (name="") => nojwtapi.get(`${EVENTS_ENDPOINT}?name=${name}`),
getEventsById: (id) => nojwtapi.get(`${EVENTS_ENDPOINT}/${id}`),
postEvents: (data) => api.post(`${EVENTS_ENDPOINT}`,data),
deleteEvents: (id) => api.delete(`${EVENTS_ENDPOINT}/${id}`),
updateEvents: (id,data) => apiPatch.patch(`${EVENTS_ENDPOINT}/${id}`,data),
//------comments (AnasDigital comments)
getComments: () => api.get(`${COMMENTS_ENDPOINT}`),
postComments: (data) => api.post(`${COMMENTS_ENDPOINT}`, data),
deleteComments: (id) => nojwtapi.delete(`${COMMENTS_ENDPOINT}/${id}`),
//------Replies (AnasDigital comments)
postReplies: (data) => api.post(`${REPLIES_ENDPOINT}`, data),
//------covers (AnasDigital comments)
getCovers: () => api.get(`${COVERS_ENDPOINT}/`),
DeleteCover: (id) => api.delete(`${COVERS_ENDPOINT}/${id}`),
postCover: (data) => api.post(`${COVERS_ENDPOINT}`, data),
//------references (AnasDigital comments)
getReferences: () => api.get(`${REFERENCES_ENDPOINT}`),
DeleteReferences: (id) => api.delete(`${REFERENCES_ENDPOINT}/${id}`),
postReferences: (data) => api.post(`${REFERENCES_ENDPOINT}`, data),
//------intervention (AnasDigital comments)
getInterventions: () => api.get(`${INTERVENTION_SPECIALTIES_ENDPOINT}`),
postInterventions: (data) =>
api.post(`${INTERVENTION_SPECIALTIES_ENDPOINT}`, data),
deleteInterventions: (id) =>
api.delete(`${INTERVENTION_SPECIALTIES_ENDPOINT}/${id}`),
patchInterventions: (data) =>
api.patch(`${INTERVENTION_SPECIALTIES_ENDPOINT}/${BULK_ENDPOINT}`, data),
//------languages (AnasDigital comments)
getLanguages: () => api.get(`${LANGUAGES_ENDPOINT}`),
postLanguages: (data) => api.post(`${LANGUAGES_ENDPOINT}`, data),
deleteLanguages: (id) => api.delete(`${LANGUAGES_ENDPOINT}/${id}`),
patchLanguages: (data) =>
api.patch(`${LANGUAGES_ENDPOINT}/${BULK_ENDPOINT}`, data),
//------parcours (AnasDigital comments)
getParcours: () => api.get(`${PARCOURS_ENDPOINT}`),
postParcours: (data) => api.post(`${PARCOURS_ENDPOINT}`, data),
deleteParcours: (id) => api.delete(`${PARCOURS_ENDPOINT}/${id}`),
patchParcours: (data) =>
api.patch(`${PARCOURS_ENDPOINT}/${BULK_ENDPOINT}`, data),
//------updateuser(AnasDigital comments)
getUpdateUser: () => api.get(`${UPDATE_USER_ENDPOINT}`),
UpdateUser: (date) => apiPatch.patch(`${UPDATE_USER_ENDPOINT}`, date),
//------posts (AnasDigital comments)
getPosts: (sortDirection,query) =>
api.get(`${POSTS_ENDPOINT}?order[created_at]=${sortDirection}&description=${query}`),
postPosts: (date) => api.post(`${POSTS_ENDPOINT}`, date),
deletePosts: (id) => nojwtapi.delete(`${POSTS_ENDPOINT}/${id}`),
//------profiles (AnasDigital comments)
//getMyProfile: () => api.get(`${ARCHITECT_BY_PROFILES_ENDPOINT}/current`),
deleteProfiles: (id) => nojwtapi.delete(`${ARCHITECT_PROFILES_ENDPOINT}/${id}`),
updateProfiles: (id,data) => apiPatch.patch(`${ARCHITECT_PROFILES_ENDPOINT}/${id}`,data),
getMyProfile: () => {
const url = `${ARCHITECT_BY_PROFILES_ENDPOINT}/current`;
const fullUrl = api.defaults.baseURL + url;
console.log("get my profile Request URL:", fullUrl); // Log the full URL
return api.get(url);
},
postProfile: (data) => {
const url = `${ARCHITECT_PROFILES_ENDPOINT}`;
const fullUrl = api.defaults.baseURL + url;
console.log("get my profile Request URL:", fullUrl); // Log the full URL
return api.post(url,data);
},
getAllProfiles: (sortDirection,query="",page=1) => nojwtapi.get(`${ARCHITECT_PROFILES_ENDPOINT}?order[created_at]=${sortDirection}&slogo=${query}&page=${page}`),
getProfiles: (sortDirection) => nojwtapi.get(`${ARCHITECT_PROFILES_ENDPOINT}/?prof=1&order[created_at]=${sortDirection}`),
UpdateProfile: (date) => apiPatch.patch(`${UPDATE_PROFILE_ENDPOINT}`, date),
getProfileByID: (id) => nojwtapi.get(`${ARCHITECT_PROFILES_ENDPOINT}/${id}/`),
getCvs: (sortDirection) => nojwtapi.get(`${ARCHITECT_PROFILES_ENDPOINT}/?prof=0&order[created_at]=${sortDirection}`),
getProfilesByCategory: (id, sortDirection, query) => {
const url = `${CATEGORY_BY_PROFILE_ENDPOINT}/${id}/${ARCHITECT_PROFILES_ENDPOINT}?order[created_at]=${sortDirection}&description=${query}`;
console.log("Request URL:", nojwtapi.defaults.baseURL + url);
return nojwtapi.get(url);
},
getMyCard: () => api.get(`${ARCHITECT_PROFILES_ENDPOINT}/me/`),
//getFeedbacksByProfile: (id) => api.get(`${ARCHITECT_PROFILES_ENDPOINT}/${id}/${FEEDBACKS_ENDPOINT}`),
getFeedbacksByProfile: (id) =>
nojwtapi.get(`${ARCHITECT_BY_PROFILES_ENDPOINT}/${id}/${FEEDBACKS_ENDPOINT}`),
postFeedbacks: (date) => api.post(`${FEEDBACK_ENDPOINT}`, date),
//------register (AnasDigital comments)
postRegister: (data) => nojwtapi.post(`${USERS_ENDPOINT}`, data),
//------Media (AnasDigital comments)
postMedia: (data) => apiMedia.post(`${MEDIA_ENDPOINT}`, data),
//------login (AnasDigital comments)
postLogin: (data) => apiMedia.post(`${LOGIN_CHECK_ENDPOINT}`, data),
//------banners (AnasDigital comments)
getAllBanners: () => nojwtapi.get(`${BANNERS_ENDPOINT}`),
postBanners: (data) => nojwtapi.post(`${BANNERS_ENDPOINT}`,data),
deleteBanners: (id) => nojwtapi.delete(`${BANNERS_ENDPOINT}/${id}`),
updateBanners: (id,data) => apiPatch.patch(`${BANNERS_ENDPOINT}/${id}`,data),

//------banners (AnasDigital comments)
getAllBannersP: () => nojwtapi.get(`${BANNERSP_ENDPOINT}`),
postBannersP: (data) => nojwtapi.post(`${BANNERSP_ENDPOINT}`,data),
deleteBannersP: (id) => nojwtapi.delete(`${BANNERSP_ENDPOINT}/${id}`),
updateBannersP: (id,data) => apiPatch.patch(`${BANNERSP_ENDPOINT}/${id}`,data),
//------users (AnasDigital comments)
getAllUsers: (query = "") => api.get(`${USERS_ENDPOINT}${query !== "" ? `?first_name=${query}` : ""}`),
deleteUsers: (id) => api.delete(`${USERS_ENDPOINT}/${id}`),
updateUsers: (id,data) => apiPatch.patch(`${USERS_ENDPOINT}/${id}`,data),
//------users (AnasDigital comments)
getAllUsersByCategory: (query = "") => api.get(`${USERBYCATEGORY_ENDPOINT}/${query !== "" ? `${query}` : ""}`),
//------profile_reports (AnasDigital comments)
getAllProfilesReports: () => api.get(`${PROFILES_REPORTS_ENDPOINT}`),
deleteProfilesReports: (id) => api.delete(`${PROFILES_REPORTS_ENDPOINT}/${id}`),
//------posts_reports (AnasDigital comments)
getAllPostsReports: () => api.get(`${POSTS_REPORTS_ENDPOINT}`),
deletePostsReports: (id) => api.delete(`${POSTS_REPORTS_ENDPOINT}/${id}`),
//------posts_reports (AnasDigital comments)
getAllCommentsReports: () => api.get(`${COMMENTS_REPORTS_ENDPOINT}`),
deleteCommentsReports: (id) => api.delete(`${COMMENTS_REPORTS_ENDPOINT}/${id}`),
postContact: (data) => nojwtapi.post(`contact`,data),
postAccountAccess: (data) => nojwtapi.post(`contactbtp`,data),
//------ProfileRequest (AnasDigital comments)
getProfileRequest: (query = "") => api.get(`${PROFILEREQUEST_ENDPOINT}${query !== "" ? `?email=${query}` : ""}`),
updateProfileRequest: (id,data) => apiPatch.patch(`${PROFILEREQUEST_ENDPOINT}/${id}`,data),
};
export default apiMethods;