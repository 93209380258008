import arch1 from'../assets/images/arch1.png';
import arch2 from'../assets/images/arch2.png';
import arch3 from'../assets/images/arch3.png';
import cat1 from'../assets/images/cat1.png';
import cat2 from'../assets/images/cat2.png';
import cat3 from'../assets/images/cat3.png';
import cat4 from'../assets/images/cat4.png';
import cat5 from'../assets/images/cat5.png';
import event1 from'../assets/images/event1.png';
import event2 from'../assets/images/event2.png';
import event3 from'../assets/images/event3.png';
import logo from'../assets/images/logo.png';
import homebg from'../assets/images/homebg.jpg';
import maintenance  from'../assets/images/maint.png';
import cv1  from'../assets/images/cv1.png';
import cv2  from'../assets/images/cv2.png';
import cv3  from'../assets/images/cv3.png';
import cv4  from'../assets/images/cv4.jpg';
import manonpic  from'../assets/images/mannopic.png';
import womenonpic  from'../assets/images/womennopic.png';
import search1  from'../assets/images/search1.png';
import search2  from'../assets/images/search2.png';
import singledisplaybg  from'../assets/images/singledisplaybg.png';
import singledisplayprofile  from'../assets/images/singledisplayprofile.png';
import ref1  from'../assets/images/ref1.png';
import ref2 from'../assets/images/ref2.png';
import ref3  from'../assets/images/ref3.png';
import map  from'../assets/images/map.png';
import feedback1  from'../assets/images/feedback1.png';
import feedback2  from'../assets/images/feedback2.png';
import wall1  from'../assets/images/wall1.png';
import wall2  from'../assets/images/wall2.png';
import spinner  from'../assets/images/spinner.gif';
import empty  from'../assets/images/empty.png';
import shape  from'../assets/images/shape.png';
import architect  from'../assets/images/architect.png';
import internship from'../assets/images/internship.png';
import folder from'../assets/images/folder.png';
import camera from'../assets/images/camera.png';
import nodata from'../assets/images/nodata2.png';
import location from'../assets/images/location.png';
import location_2 from'../assets/images/location-2.png';
import pub1 from'../assets/images/pub1.jpg';
import pub2 from'../assets/images/pub2.jpg';
import contact from'../assets/images/contact-us.png';
import settings from'../assets/images/settings.png';
import user_bg from'../assets/images/user_bg.png';
import stamp from'../assets/images/stamp.png';
import terms from'../assets/images/terms.png';
import loader from'../assets/images/loader.gif';
import landingbg from'../assets/images/header-bg.png';
import phones from'../assets/images/phones.png';
import who from'../assets/images/who.png';
import how from'../assets/images/how.png';
import logo_white from'../assets/images/logo-white.png';
import logo_fullblack from'../assets/images/fullblack.png';
import black from'../assets/images/logo-black.png';
import footerbg from'../assets/images/footerbg.png';
import schema from'../assets/images/schema.png';
import prof_type from'../assets/images/prof_type.png';
import profile from'../assets/images/profil.png';
import etudiant from'../assets/images/etudiant.png';
import deleteicon from'../assets/images/delete.gif';
import s1 from'../assets/images/s1.png';
import s2 from'../assets/images/s2.png';
import s3 from'../assets/images/s3.png';
import s4 from'../assets/images/s4.png';
import s5 from'../assets/images/s5.png';
import s6 from'../assets/images/s6.png';
import s7 from'../assets/images/s7.png';
import s8 from'../assets/images/s8.png';
import s9 from'../assets/images/s9.png';
import s10 from'../assets/images/s10.png';
import s11 from'../assets/images/s11.png';
import s12 from'../assets/images/s12.png';
import large_logo from'../assets/images/large_logo.png';
import white_large_logo from'../assets/images/white_large_logo.png';
import { BASE_URL } from './constants/apiEndpoints';

const noimage = BASE_URL + "istockphoto-1131164548-612x612.png";
export default {
    arch1,nodata,location,pub1,pub2,settings,contact,user_bg,stamp,terms,loader,location_2,landingbg,phones,who,how
    ,arch2,logo_white,footerbg,schema,black,noimage,prof_type,etudiant,profile,deleteicon,
    arch3,logo_fullblack,s1,s2,s3,s4,s5,s6,s7,s8,s9,s10,s11,s12,large_logo,
    cat1,white_large_logo,
    cat2,
    cat3,
    cat4,
    cat5,
    event1,
    event2,
    event3,
    logo,
    homebg,
    maintenance,
    cv2,
    cv1,
    cv3,
    cv4,
    manonpic,
    womenonpic,
    search1,
    search2,
    singledisplaybg,
    ref1,
    ref2,
    ref3,folder,camera,
    map,feedback1,feedback2,singledisplayprofile,wall1,wall2,spinner,empty,shape,internship,architect
}