

import { BASE_URL } from "../../config/constants/apiEndpoints";
import { useDateFormat } from "../../config/hooks/general";
import images from "../../config/images";
import React, { useEffect, useState,useRef } from "react";
import apiMethods from '../../config/services/ApiService'
import { Link } from "react-router-dom";
import { GoogleMap, useJsApiLoader, Marker,OverlayView ,Polygon  } from '@react-google-maps/api';

export const Profiles = ({ item }) => {
    return(
        <>
         <Link style={{textDecoration:'none'}} to={`/profile/${item.id}`}>
        <div className='pt-3 pb-1 mb-3' style={{borderRadius:8,boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px"}} >


        <div className='firstrow' style={{borderRadius:4}} >
        <div className='row' style={{}} >
        <div className='col-sm-2 col-3 ms-3 me-3' style={{}} >
          <img src={   item.avatar
                              ?  BASE_URL+  item.avatar
                              : images.noimage}  style={{
                                height: 80,
                                borderRadius: 8,
                                width: 80}} />
     
                      </div>
                      <div className='col' style={{}} >
                      <div className='row' style={{}} >
                      <div className='col-auto' style={{maxWidth:250}} >
                            <span className="txt-md txt-color-2 dotscompleter-1 "   >
                            {item.slogo ? item.slogo : "Indisponible"}
                            
                              </span>
                            </div>
                          
                            <div className='col-auto' style={{}} >
                            {item.user.pack==1 &&
                            <div className='    plat_pack me-2' style={{}} >
                           Premuim
                                </div>
}                                {item.user.pack==2 &&
<>
                                <div className='    gold_pack' style={{}} >
                           Gold
                                </div>
                                </>}
                     
                            </div>
                            </div>
                            <span className="txt-sm  txt-color-1 "   >{item.category.name}</span><br></br>
                            <i className="me-1 fa-solid fa-location-dot primarytxt txt-xs " />
                            <span className="txt-xs  txt-color-3 "   >
                            {item.address ? item.address : "Indisponible"}  
                              </span><br></br>
                          </div>
                      </div>
      

                      </div>



                      <div className='row' style={{maxHeight:30,height:35,overflow:'hidden'}} >
        <div className='col-2 ms-3 me-3' style={{}} >
    
        </div>
        <div className='col' style={{}} >
        <div className='row ps-sm-2 ps-5 ' style={{}} >
        {item.specialities.length!=0 &&
        (
        item.specialities.map((speciality, index) => {
    return index < 3 ? (
        <div className='col-auto p-0' style={{}} >
                            <div className='   primary_badge me-2 mb-3' style={{}} >
                           {speciality.name}
                                </div>
                                </div>
                                    ) : null;
                                }))
                              
                                }
                              {item.specialities.length===0 &&
                           <div className='   primary_badge me-2 mb-3' style={{maxWidth:100}} >
                          Indisponible
                               
                                </div>
}
                                </div>

        </div>
        </div>



        </div>
        </Link>
        </>
    )

}


const getPixelPositionOffset = (width, height) => ({
    x: -(width / 2),
    y: -(height / 2),
  });
  
  const CustomMarker = ({ position, text,avatar,id }) => (
    <OverlayView
      position={position}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      getPixelPositionOffset={getPixelPositionOffset}
    >
          <Link style={{textDecoration:'none'}} to={`/profile/${id}`}>
        <img src={ avatar ?  BASE_URL+ avatar : images.noimage} style={{width:40,height:40,borderRadius:100,border:'2px solid #fff',boxShadow: "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px"}}  />
        </Link>
 
    </OverlayView>
  );
const containerStyle = {
    width: '100%',
    height: '100%'
  };
  
  const center = {
    lat: 31.630000,
    lng: -8.008889
  };

  const regionCoordinates = [
    { lat: 31.7917, lng: -7.0926 },
    // Add more coordinates to outline your custom region
  ];

  
  const options = {
    styles: [
      {
        featureType: "administrative",
        elementType: "geometry.stroke",
        stylers: [{ visibility: "off" }], // Attempt to hide administrative borders
      },
      // Further style customization here
    ],
  };


  export const MapComponent = ({ items }) => {
    const { isLoaded } = useJsApiLoader({
      id: 'google-map-script',
      googleMapsApiKey: "AIzaSyCNq53xRUJyTM2USlmPvOKfnaRha-f3xCI" 
    });
  
    return isLoaded ? (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={5}
        options={options}
      >
          <Polygon
        paths={regionCoordinates}
        strokeColor="#FF0000"
        strokeOpacity={0.8}
        strokeWeight={2}
        fillColor="#FF0000"
        fillOpacity={0.35}
      />
        {items.map((item, index) => {
          const latitude = parseFloat(item.lalt);
          const longitude = parseFloat(item.longt);
  
          // Ensure latitude and longitude are valid numbers
          if (isNaN(latitude) || isNaN(longitude)) {
            return null;  // Don't render this Marker
          }
  
          return (
            <CustomMarker id={item.id} avatar={item.avatar}  key={index} position={{ lat: latitude, lng: longitude }} />
          );
        })}
      </GoogleMap>
    ) : <></>;
  };
  
  export default React.memo(MapComponent);