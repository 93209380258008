import images from "../../../config/images";
import React, { useEffect, useState } from "react";
import "./style.css";
import apiMethods from "../../../config/services/ApiService";
import { BASE_URL } from "../../../config/constants/apiEndpoints";
import { CustomModal } from "../../../components/modal";
import Dashboard from "../index";
import { Link } from "react-router-dom";
const Index = () => {
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isAddModalOpen, setADDModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [name, setName] = useState("");
  const [model_type, setModel_type] = useState("");
  const [color, setColor] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const [categoriesList, setProfilesList] = useState([]);
  const [totalProfilesCount, setTotalProfilesCount] = useState(0);
  const itemsPerPage = 200;
  const [profiles, setProfiles] = useState([]);
  // Function to calculate the total number of pages
  const totalPages = Math.ceil(totalProfilesCount / itemsPerPage);
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const onFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  const deleteModel = async (id) => {
    setButtonLoading(true);
    try {
      const response = await apiMethods.deleteProfiles(id);
      if (response.data) {
        setDeleteModalOpen(false);
      }
    } catch (error) {
      console.error("Error fetching Profiles:", error);
    }
    fetchProfiles();
    setButtonLoading(false);
    setDeleteModalOpen(false);
  };

  const fetchProfiles = async () => {
    setLoading(true);
    try {
      const response = await apiMethods.getAllProfiles("desc", search, page);
      if (response.data) {
        setProfilesList(response.data.data);
        setTotalProfilesCount(response.data.total_profiles_count);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching Profiles:", error);
    }
  };
  useEffect(() => {
    fetchProfiles();
  }, [search, page]);



  const updateModel = async (id) => {
    setButtonLoading(true); // Activate loading state as soon as function is called
    let eventData = {
      "officeemail": name,
    "address":model_type 
    }
    try {
  
      const categoryResponse = await apiMethods.updateProfiles(
        id,
        eventData
      );

      // Check if category post is successful
      if (categoryResponse.data) {
        setEditModalOpen(false);
        fetchProfiles();
      }
    } catch (error) {
      console.error("Error fetching Events:", error);
    } finally {
      setButtonLoading(false); // Deactivate loading state whether API call is successful or failed
      setEditModalOpen(false);
      setName("");
      setColor("");
      setModel_type("");
      setSelectedItem("");
    }
  };

  return (
    <Dashboard>
      <>
        <div className="">
          {/* page title */}
          <span className=" txt-l blacktxt fw-bold ">Profiles</span>
          <br></br>
          <span className=" txt-xs graytxt">Dashboard / Profiles</span>
          {/* page title end */}
          {/* data card */}
          <div className="a-card-container whitebg mt-3 ">
            <div className="p-2 ">
              {/* first row */}
              <div className="row mb-3">
                {/* search */}
                <div className="col-auto my-auto mb-2">
                  <div className="d-flex lightbg btn-radius ">
                    <svg
                      className="mx-auto my-auto ms-2"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        opacity="0.5"
                        x="17.0365"
                        y="15.1223"
                        width="8.15546"
                        height="2"
                        rx="1"
                        transform="rotate(45 17.0365 15.1223)"
                        fill="currentColor"
                      ></rect>
                      <path
                        d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                    <input
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      className="ms-2 lightbg inputs-container"
                      placeholder="Rechereche"
                    />
                  </div>
                </div>
                {/* search end */}
                {/* add button */}
                {/* <div className="col-auto ms-auto ">
<button
className="a-btn primary txt-sm "
onClick={() => {
setName("");
setColor("");
setSelectedFile("");
setSelectedItem("");
setADDModalOpen(true);
}}
>
Ajouter Un Profile
</button>
</div> */}
                {/* add button end */}
              </div>
              {/* first row end */}
              {/* second row */}
              <div className="row ">
                {!loading ? (
                  <div className="table-container" style={{ maxHeight: 500 }}>
                    <>
                      <table style={{ maxHeight: 300 }}>
                        <tr>
                          <th>Name</th>
                          <th>Office Email</th>
                          <th>Office Address</th>
                          <th>Actions</th>
                        </tr>
                        {categoriesList.map((item, index) => {
                          return (
                            <>
                              <tr key={index}>
                                <td>
                                  <Link
                                    style={{ textDecoration: "none" }}
                                    to={`/profile/${item.id}`}
                                  >
                                    <div
                                      className="row"
                                      style={{ flexWrap: "nowrap" }}
                                    >
                                      <div className="col-auto">
                                        <div
                                          className=""
                                          style={{
                                            background: `url("${
                                              item.avatar
                                                ? BASE_URL + item.avatar
                                                : images.noimage
                                            }")`,
                                            backgroundPosition: "center",
                                            height: 50,
                                            width: 50,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "cover",
                                            borderRadius: "100%",
                                          }}
                                        ></div>
                                      </div>
                                      <div className="col-auto my-auto">
                                        <span className=" dotscompleter-1 txt-sm blacktxt ">
                                          {item.slogo}
                                        </span>
                                      </div>
                                    </div>
                                  </Link>
                                </td>
                                <td>
                                  <span className=" txt-sm dotscompleter-1">
                                    {item.officeemail}
                                  </span>
                                </td>
                                <td>
                                  <span className=" txt-sm dotscompleter-1">
                                    {item.address}
                                  </span>
                                </td>
                                <td className="actions">
                                  <div
                                    className="row"
                                    style={{ flexWrap: "nowrap" }}
                                  >
                                        <div className="col-auto">
                                  <button
                                    className="btn-0"
                                    onClick={() => {
                                      setEditModalOpen(true);
                                      setSelectedItem(item);
                                      setName(item.officeemail);
                                      setModel_type(item.address);
                                  
                                    }}
                                  >
                                    <svg
                                      width="20"
                                      height="20"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M3 17.25V21H6.75L17.81 9.94L14.06 6.19L3 17.25Z"
                                        fill="currentColor"
                                      ></path>
                                      <path
                                        d="M20.71 7.04C21.1 6.65 21.1 6.02 20.71 5.63L18.37 3.29C17.98 2.9 17.35 2.9 16.96 3.29L15.13 5.12L18.88 8.87L20.71 7.04Z"
                                        fill="currentColor"
                                        opacity="0.3"
                                      ></path>
                                    </svg>
                                  </button>
                                </div>
                                    <div className="col-auto">
                                      <button
                                        className="btn-0"
                                        onClick={() => {
                                          setDeleteModalOpen(true);
                                          setSelectedItem(item);
                                        }}
                                      >
                                        <svg
                                          width="20"
                                          height="20"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7H6V19ZM16 4H18V6H6V4H8L9 3H15L16 4Z"
                                            fill="currentColor"
                                          ></path>
                                          <path
                                            d="M9 9H11V17H9V9ZM13 9H15V17H13V9Z"
                                            fill="currentColor"
                                            opacity="0.3"
                                          ></path>
                                        </svg>
                                      </button>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </>
                          );
                        })}
                      </table>
                    </>
                  </div>
                ) : (
                  <div className=" d-flex w-100 " style={{ height: 400 }}>
                    <img
                      className="mx-auto my-auto"
                      src={images.spinner}
                      style={{ width: 50 }}
                    />
                  </div>
                )}

                {!loading && page > 1 && (
                  <button
                    className=" a-btn small primary me-2 w-auto"
                    onClick={() => handlePageChange(page - 1)}
                  >
                    Précédente
                  </button>
                )}
                {!loading && page < totalPages && (
                  <button
                    className=" a-btn small primary w-auto"
                    onClick={() => handlePageChange(page + 1)}
                  >
                    Suivant
                  </button>
                )}
              </div>
              {/* second row end*/}
            </div>
          </div>
          {/* data card end */}
        </div>
        <CustomModal
          isOpen={isDeleteModalOpen}
          onRequestClose={() => setDeleteModalOpen(false)}
          contentLabel="Example Modal"
        >
          <div className="col-12 d-flex mb-3">
            <img
              className="mx-auto"
              src={images.deleteicon}
              style={{ width: 80, height: 100 }}
            />
          </div>
          <h4>Confirmer la suppression</h4>
          <p>
            Etes-vous sûr que vous voulez supprimer: <br></br>{" "}
            {selectedItem?.name} ?
          </p>
          <div className="row mt-2">
            <div className="col-12 d-flex">
              <div className="ms-auto ">
                <button
                  className="a-btn primary me-2"
                  onClick={() => {
                    deleteModel(selectedItem?.id);
                  }}
                >
                  {!buttonLoading ? (
                    "Yes"
                  ) : (
                    <img
                      className="mx-auto my-auto"
                      src={images.spinner}
                      style={{ width: 20 }}
                    />
                  )}
                </button>
                <button
                  className="a-btn"
                  onClick={() => setDeleteModalOpen(false)}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </CustomModal>
        <CustomModal
        isOpen={isEditModalOpen}
        onRequestClose={() => setEditModalOpen(false)}
        contentLabel="Example Modal"
      >
        <div className="mb-2">
          <span className=" blacktxt txt-sm ">Email</span>
          <br></br>
          <input
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="inputs-container lightbg bs-1 ps-2 mt-2"
            placeholder="Email"
            style={{ width: 400 }}
          />
        </div>

        <div className="mb-2">
          <span className=" blacktxt txt-sm ">Address</span>
          <br></br>
          <input
            value={model_type}
            onChange={(e) => setModel_type(e.target.value)}
            className="inputs-container lightbg bs-1 ps-2 mt-2"
            placeholder="Address"
            style={{ width: 400 }}
          />
        </div>
       
      

        <div className="row mt-4">
          <div className="col-12 d-flex">
            <div className="ms-auto ">
              <button
                className="a-btn-small primary me-2 txt-sm "
                onClick={()=>updateModel(selectedItem?.id)}
              >
                {!buttonLoading ? (
                  "Sauvegarder"
                ) : (
                  <img
                    className="mx-auto my-auto"
                    src={images.spinner}
                    style={{ width: 20 }}
                  />
                )}
              </button>
              <button
                className="a-btn-small txt-sm"
                onClick={() => setEditModalOpen(false)}
              >
                Annuler
              </button>
            </div>
          </div>
        </div>
      </CustomModal>
      </>
    </Dashboard>
  );
};
export default Index;
