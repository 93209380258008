import images from "../../../config/images";
import React, { useEffect, useState } from "react";
import "./style.css";
import apiMethods from "../../../config/services/ApiService";
import { BASE_URL } from "../../../config/constants/apiEndpoints";
import { CustomModal } from "../../../components/modal";
import Dashboard from "../index";

const Index = () => {
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isAddModalOpen, setADDModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [model_type, setModel_type] = useState("");
  const [color, setColor] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const [eventsList, setEventsList] = useState([]);

  const onFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const deleteModel = async (id) => {
    setButtonLoading(true);
    try {
      const response = await apiMethods.deleteEvents(id);
      if (response.data) {
        setDeleteModalOpen(false);
      }
    } catch (error) {
      console.error("Error fetching Events:", error);
    }
    fetchEvents();
    setButtonLoading(false);
    setDeleteModalOpen(false);
  };

  // API calls using async/await syntax
  const postModel = async () => {
    setButtonLoading(true); // Activate loading state as soon as function is called

    try {
      // API Call 1: Upload Media
      const formData = new FormData();
      formData.append("imageFile", selectedFile);
      const mediaResponse = await apiMethods.postMedia(formData);
      // Check if media upload is successful
      if (mediaResponse.data) {
        // Construct category data
        const eventData = {
          "name": name,
        "image": mediaResponse.data.url,
        "type":model_type ,
        "description": description
        };

        // API Call 2: Post Category
        const categoryResponse = await apiMethods.postEvents(eventData);

        // Check if category post is successful
        if (categoryResponse.data) {
          setADDModalOpen(false);
          fetchEvents();
        }
      }
    } catch (error) {
      console.error("Error fetching Events:", error);
    } finally {
      setButtonLoading(false); // Deactivate loading state whether API call is successful or failed
      setADDModalOpen(false);
    }
  };

  const updateModel = async (id) => {
    setButtonLoading(true); // Activate loading state as soon as function is called
    let eventData = {
      "name": name,
    "type":model_type ,
    "description": description
    }
    try {
      if(selectedFile){
      const formData = new FormData();
      formData.append("imageFile", selectedFile);
      const mediaResponse = await apiMethods.postMedia(formData);
      // Check if media upload is successful
      if (mediaResponse.data) {
        // Construct category data
        eventData = {
          "name": name,
        "image": mediaResponse.data.url,
        "type":model_type ,
        "description": description
        };
      }
    }
      const categoryResponse = await apiMethods.updateEvents(
        id,
        eventData
      );

      // Check if category post is successful
      if (categoryResponse.data) {
        setEditModalOpen(false);
        fetchEvents();
      }
    } catch (error) {
      console.error("Error fetching Events:", error);
    } finally {
      setButtonLoading(false); // Deactivate loading state whether API call is successful or failed
      setEditModalOpen(false);
      setName("");
      setColor("");
      setSelectedFile("");
      setSelectedItem("");
    }
  };

  const fetchEvents = async () => {
    setLoading(true);
    try {
      const response = await apiMethods.getEvents(search);
      if (response.data) {
        setEventsList(response.data);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching Events:", error);
    }
  };

  useEffect(() => {
    fetchEvents();
  }, [search]);

  return (
<Dashboard>
    <>
      <div className="">
        {/* page title */}
        <span className=" txt-l blacktxt fw-bold ">Events</span>
        <br></br>
        <span className=" txt-xs graytxt">Dashboard / Events</span>
        {/* page title end  */}
        {/* data card */}
        <div className="a-card-container whitebg mt-3 ">
          <div className="p-2 ">
            {/* first row */}
            <div className="row mb-3">
              {/* search */}
              <div className="col-auto  my-auto mb-2">
                <div className="d-flex  lightbg btn-radius ">
                  <svg
                    className="mx-auto my-auto ms-2"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      opacity="0.5"
                      x="17.0365"
                      y="15.1223"
                      width="8.15546"
                      height="2"
                      rx="1"
                      transform="rotate(45 17.0365 15.1223)"
                      fill="currentColor"
                    ></rect>
                    <path
                      d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                  <input
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    className="ms-2 lightbg inputs-container"
                    placeholder="Rechereche"
                  />
                </div>
              </div>
              {/* search end */}
              {/* add button  */}
              <div className="col-auto ms-auto  ">
                <button
                  className="a-btn  primary txt-sm "
                  onClick={() => {
                    setName("");
                    setColor("");
                    setSelectedFile("");
                    setSelectedItem("");
                    setADDModalOpen(true);
                  }}
                >
                  Ajouter Event
                </button>
              </div>
              {/* add button end */}
            </div>
            {/* first row end */}
            {/* second row */}
            <div className="row ">
              <div className="table-container">
                {!loading ? (
                  <table>
                    <tr>
                      <th>ID</th>
                      <th>Name</th>
                      <th>Type</th>
                      <th>Image</th>
                      <th>Actions</th>
                    </tr>
                    {eventsList.map((item, index) => {
                      return (
                        <>
                          <tr key={index}>
                            <td className=" txt-sm">{item.id}</td>
                            <td className=" txt-sm">{item.name}</td>
                            <td className=" txt-sm">{item.type}</td>
                            <td>
                              <div
                                className=""
                                style={{
                                  background: `url("${BASE_URL + item.image}")`,
                                  backgroundPosition: "center",
                                  height: 60,
                                  width: 100,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "cover",
                                  borderRadius: 4,
                                }}
                              ></div>
                            </td>
                            <td className="actions">
                              <div
                                className="row"
                                style={{ flexWrap: "nowrap" }}
                              >
                                <div className="col-auto">
                                  <button
                                    className="btn-0"
                                    onClick={() => {
                                      setEditModalOpen(true);
                                      setSelectedItem(item);
                                      setName(item.name);
                                      setModel_type(item.type);
                                      setDescription(item.description);
                                    }}
                                  >
                                    <svg
                                      width="20"
                                      height="20"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M3 17.25V21H6.75L17.81 9.94L14.06 6.19L3 17.25Z"
                                        fill="currentColor"
                                      ></path>
                                      <path
                                        d="M20.71 7.04C21.1 6.65 21.1 6.02 20.71 5.63L18.37 3.29C17.98 2.9 17.35 2.9 16.96 3.29L15.13 5.12L18.88 8.87L20.71 7.04Z"
                                        fill="currentColor"
                                        opacity="0.3"
                                      ></path>
                                    </svg>
                                  </button>
                                </div>
                                <div className="col-auto">
                                  <button
                                    className="btn-0"
                                    onClick={() => {
                                      setDeleteModalOpen(true);
                                      setSelectedItem(item);
                                    }}
                                  >
                                    <svg
                                      width="20"
                                      height="20"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7H6V19ZM16 4H18V6H6V4H8L9 3H15L16 4Z"
                                        fill="currentColor"
                                      ></path>
                                      <path
                                        d="M9 9H11V17H9V9ZM13 9H15V17H13V9Z"
                                        fill="currentColor"
                                        opacity="0.3"
                                      ></path>
                                    </svg>
                                  </button>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </table>
                ) : (
                  <div className=" d-flex w-100 " style={{ height: 400 }}>
                    <img
                      className="mx-auto my-auto"
                      src={images.spinner}
                      style={{ width: 50 }}
                    />
                  </div>
                )}
              </div>
            </div>
            {/* second row  end*/}
          </div>
        </div>
        {/* data card end */}
      </div>

      <CustomModal
        isOpen={isDeleteModalOpen}
        onRequestClose={() => setDeleteModalOpen(false)}
        contentLabel="Example Modal"
      >
        <div className="col-12 d-flex mb-3">
          <img
            className="mx-auto"
            src={images.deleteicon}
            style={{ width: 80,height:100 }}
          />
        </div>
        <h4>Confirmer la suppression</h4>
        <p>
          Etes-vous sûr que vous voulez supprimer: <br></br>{" "}
          {selectedItem?.name} ?
        </p>
        <div className="row mt-2">
          <div className="col-12 d-flex">
            <div className="ms-auto ">
              <button
                className="a-btn primary me-2"
                onClick={() => {
                  deleteModel(selectedItem?.id);
                }}
              >
                {!buttonLoading ? (
                  "Yes"
                ) : (
                  <img
                    className="mx-auto my-auto"
                    src={images.spinner}
                    style={{ width: 20 }}
                  />
                )}
              </button>
              <button
                className="a-btn"
                onClick={() => setDeleteModalOpen(false)}
              >
                No
              </button>
            </div>
          </div>
        </div>
      </CustomModal>

   

      <CustomModal
        isOpen={isAddModalOpen}
        onRequestClose={() => setADDModalOpen(false)}
        contentLabel="Example Modal"
      >
        <div className="mb-2">
          <span className=" blacktxt txt-sm ">Name</span>
          <br></br>
          <input
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="inputs-container lightbg bs-1 ps-2 mt-2"
            placeholder="Name"
            style={{ width: 400 }}
          />
        </div>

        <div className="mb-2">
          <span className=" blacktxt txt-sm ">Type</span>
          <br></br>
          <input
            value={model_type}
            onChange={(e) => setModel_type(e.target.value)}
            className="inputs-container lightbg bs-1 ps-2 mt-2"
            placeholder="Type"
            style={{ width: 400 }}
          />
        </div>
        <div className="mb-2">
          <span className=" blacktxt  txt-sm ">Image</span>
          <br></br>

          <input
            onChange={onFileChange}
            type="file"
            className="inputs-container lightbg bs-1 ps-2 mt-2"
            placeholder="Name"
            style={{ width: 400 }}
          />
        </div>
        <div className="mb-2">
          <span className=" blacktxt txt-sm ">Description</span>
          <br></br>
          <textarea 
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="inputs-container lightbg  bs-1  mt-2"
            placeholder="Description"
            style={{
              width: "100%",
              height: 200,
              backgroundColor: "transparent",
              border: "none",
              borderRadius: 8,
              outline: "none",
            }}
          />
        </div>

        <div className="row mt-4">
          <div className="col-12 d-flex">
            <div className="ms-auto ">
              <button
                className="a-btn-small primary me-2 txt-sm "
                onClick={postModel}
              >
                {!buttonLoading ? (
                  "Sauvegarder"
                ) : (
                  <img
                    className="mx-auto my-auto"
                    src={images.spinner}
                    style={{ width: 20 }}
                  />
                )}
              </button>
              <button
                className="a-btn-small txt-sm"
                onClick={() => setADDModalOpen(false)}
              >
                Annuler
              </button>
            </div>
          </div>
        </div>
      </CustomModal>

      <CustomModal
        isOpen={isEditModalOpen}
        onRequestClose={() => setEditModalOpen(false)}
        contentLabel="Example Modal"
      >
        <div className="mb-2">
          <span className=" blacktxt txt-sm ">Name</span>
          <br></br>
          <input
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="inputs-container lightbg bs-1 ps-2 mt-2"
            placeholder="Name"
            style={{ width: 400 }}
          />
        </div>

        <div className="mb-2">
          <span className=" blacktxt txt-sm ">Type</span>
          <br></br>
          <input
            value={model_type}
            onChange={(e) => setModel_type(e.target.value)}
            className="inputs-container lightbg bs-1 ps-2 mt-2"
            placeholder="Type"
            style={{ width: 400 }}
          />
        </div>
        <div className="mb-2">
          <span className=" blacktxt txt-sm ">Image</span>
          <br></br>

          <input
            onChange={onFileChange}
            type="file"
            className="inputs-container lightbg bs-1 ps-2 mt-2"
            placeholder="Name"
            style={{ width: 400 }}
          />
        </div>
        <div className="mb-2">
          <span className=" blacktxt txt-sm ">Description</span>
          <br></br>
          <textarea 
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="inputs-container lightbg bs-1  mt-2"
            placeholder="Description"
            style={{
              width: "100%",
              height: 200,
              border: "none",
              borderRadius: 8,
              outline: "none",
            }}
          />
        </div>

        <div className="row mt-4">
          <div className="col-12 d-flex">
            <div className="ms-auto ">
              <button
                className="a-btn-small primary me-2 txt-sm "
                onClick={()=>updateModel(selectedItem?.id)}
              >
                {!buttonLoading ? (
                  "Sauvegarder"
                ) : (
                  <img
                    className="mx-auto my-auto"
                    src={images.spinner}
                    style={{ width: 20 }}
                  />
                )}
              </button>
              <button
                className="a-btn-small txt-sm"
                onClick={() => setEditModalOpen(false)}
              >
                Annuler
              </button>
            </div>
          </div>
        </div>
      </CustomModal>
    </>
</Dashboard>
  );
};

export default Index;
