
import { BASE_URL } from "../../config/constants/apiEndpoints";
import images from "../../config/images";
import React, { useEffect, useState,useRef } from "react";
import apiMethods from '../../config/services/ApiService'
import { Link, a } from "react-router-dom";

import axios from 'axios';

const ContactForm = ({setShow}) => {
    const [formData, setFormData] = useState({
        email: '',
        subject: '',
        message: '',
    });
    const [buttonLoading
      ,setButtonLoading] = useState(false);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setButtonLoading(true)
        try {
            const response = await apiMethods.postContact(formData);
          if(response){
            setShow(false)
            setButtonLoading(false)
          }
        
        } catch (error) {
          setShow(false)
          setButtonLoading(false)
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Your email"
                required
                className="inputs-container lightbg bs-1 ps-2 mt-2"
            
                style={{ width: '100%' }}
            />
            <input
                type="text"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                placeholder="Subject"
                required
                className="inputs-container lightbg bs-1 ps-2 mt-2"
            
                style={{ width: '100%' }}
            />
            <textarea
                name="message"
                value={formData.message}
                onChange={handleChange}
                placeholder="Your message"
                className="inputs-container lightbg bs-1 ps-2 mt-2"
            
                style={{ width: '100%',minHeight:200 }}
                required
            />
           

            <button
                className="a-btn-small primary whitetxt me-2 txt-sm w-100 "
              >
                {!buttonLoading ? (
                  "Send"
                ) : (
                  <img
                    className="mx-auto my-auto"
                    src={images.spinner}
                    style={{ width: 20 }}
                  />
                )}
              </button>

        </form>
    );
};

export default ContactForm;

export const Nav = ({ variant=1 }) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 850);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [show, setShow] = useState(false);
  const toggleModal = () => setShow(!show);

    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 850);
      };
      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);
    
    useEffect(() => {
      const checkAnimation = () => {
        // ... (your checkAnimation function logic)
      };
      window.addEventListener("scroll", checkAnimation);
      return () => {
        window.removeEventListener("scroll", checkAnimation);
      };
    }, []);
    
    const handleMenuToggle = () => {
      setIsMenuOpen(!isMenuOpen);
    };
    
  return (
   <>
    <div className="container-fluid p-0 m-0  " style={{ boxShadow:variant===1? 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px' : '',position:'sticky',zIndex:1001}} >
    <div className="container" >
       <div
     className={`row pb-3 pt-3  toggle-cols${!isMobile ? "" : " d-none"}`}
            style={{}}
          >
             <div className="col-auto p-0 original-cols  ">
             <Link to="/" className="" >
             <img style={{ width:190}} src={variant===1? images.large_logo : images.white_large_logo}/>
             </Link>
             </div>
            <div className="col-auto p-0 original-cols ms-auto ">
        
          
         
            </div>
            <div className="col-auto p-0 original-cols">
              <div className="header-tab-item  pt-2 pb-2">
              <a href="/#who" className="header-tab-item pe-2 ps-2 pt-2 pb-2"  >
                <span className={variant===1?"blacktxt" :"whitetxt" + "  txt-md"}>A propos</span>
                </a>
              </div>
            </div>
            <div className="col-auto p-0 original-cols">
              <div className="header-tab-item pe-2 ps-2 pt-2 pb-2">
              <a href="/#categories" className="header-tab-item pe-2 ps-2 pt-2 pb-2" >
                <span className={variant===1?"blacktxt" :"whitetxt" + "  txt-md"}>Catégories</span>
                </a>
              </div>
            </div>
            <div className="col-auto p-0 original-cols">
              <div className="header-tab-item pe-2 ps-2 pt-2 pb-2">
              <a href="/#events" className="header-tab-item pe-2 ps-2 pt-2 pb-2" >
                <span className={variant===1?"blacktxt" :"whitetxt" + "  txt-md"}>Actualités et évenements</span>
                </a>
              </div>
            </div>
            <div className="col-auto p-0 original-cols">
              <div className="header-tab-item pe-2 ps-2 pt-2 pb-2">
              <button type="button" 
              className={variant===1?"blacktxt btn-0 " :"whitetxt" + "  txt-md btn-0 "}
              onClick={toggleModal}>
        Contact
      </button>
               
              </div>
            </div>
            {/* <div className="col-auto ms-auto mt-auto mb-auto  original-cols">
              <i className="fa-solid fa-globe txt-md whitetxt header-tab-item" />
              <i className="fa-regular fa-heart txt-md whitetxt ms-3 header-tab-item" />
              <button className="btn white-outline-btn ms-3">
                <a
                  href="#"
                  className="fw-bold txt-sm text-decoration-none whitetxt"
                >
                  <i className="fa-regular fa-user me-2" />
                  Voir plus
                </a>
              </button>
            </div> */}
          </div>
          <div style={{zIndex:1000}}
              className={"row pb-4 pt-3 mb-5 toggle-row " + (isMobile ? "" : "d-none")}
          >
            
            <div className="col p-0 ">
              <img style={{ width: 100 }} src={images.large_logo}/>
            </div>
            <div className="col-auto p-0 ms-auto mt-auto mb-auto">
            <button 
    id="menu-toggle-btn" 
    onClick={handleMenuToggle} 
    className={"dynamic-btn ms-auto " + (isMobile ? "" : "d-none")}
>

                <i className="fa-solid fa-bars txt-lg blacktxt " />
              </button>
            </div>
          </div>
          <div  style={{zIndex:1000}}
        id="fullscreen-menu" 
        className={isMenuOpen ? "" : "d-none"}
      >
            <div className="container-fluid">
              <div className="row  pt-3 toggle-row mb-5 ">
                <div className="col-auto  ">
                  <img
                    style={{ width: 100 }}
                    src={images.black}
                  />
                </div>
                <div className="col-auto p-0 ms-auto mt-auto mb-auto">
                  <button id="close-menu-btn" onClick={handleMenuToggle}  className="dynamic-btn ms-auto  ">

                    <i className="fa-solid fa-xmark txt-lg  primarytxt me-2 " />
                  </button>
                </div>
              </div>
              <div className="row pb-3 mb-1  ">
                <div className="col-12 ms-auto mt-auto mb-auto  text-center p-0 ">
            
                  <div className="menu-tab-item pe-2 ps-2 pt-2 pb-2 selected-tabs-item">
              
            
                  </div>
                 
                </div>
                <div className="col-12 ms-auto mt-auto mb-auto text-center ">
                  <div className="menu-tab-item pe-2 ps-2 pt-2 pb-2">
                    <a href="/#who" className="blacktxt text-decoration-none"  onClick={handleMenuToggle}  >
                    <span className="fw-bold blacktxt txt-lg">A propos</span>
                    </a>
                  </div>
                </div>
                <div className="col-12 ms-auto mt-auto mb-auto text-center  ">
                  <div className="menu-tab-item pe-2 ps-2 pt-2 pb-2">
                  <a href="/#categories" className="blacktxt text-decoration-none" onClick={handleMenuToggle}   >
                    <span className="fw-bold blacktxt txt-lg">Catégories</span>
                    </a>
                  </div>
                </div>
                <div className="col-12 ms-auto mt-auto mb-auto text-center ">
                  <div className="menu-tab-item pe-2 ps-2 pt-2 pb-2">
                  <a href="/#events"  className="blacktxt text-decoration-none " onClick={handleMenuToggle}  >
                    <span className="fw-bold blacktxt txt-lg">
                      Actualités et évenements
                    </span>
                    </a>
                  </div>
                </div>
                <div className="col-12 ms-auto mt-auto mb-auto  text-center ">
                  <div className="menu-tab-item pe-2 ps-2 pt-2 pb-2">
                  <button type="button" className="blacktxt txt-md btn-0" onClick={()=>{ toggleModal();handleMenuToggle()}}>
        
        <span className="fw-bold blacktxt txt-lg">
        Contact
                    </span>
      </button>
                  </div>
                </div>
                <div className="col-12 ms-auto mt-5  ps-2 ">
                  {/* <div className="row">
                    <div className="col mt-auto mb-auto ps-4">
                      <i className="fa-solid fa-globe txt-md primarytxt" />
                      <i className="fa-regular fa-heart txt-md primarytxt ms-3" />
                    </div>
                    <div className="col-auto">
                      <button className="btn primarybtn ms-auto">
                        <a
                          href="#"
                          className="fw-bold txt-sm text-decoration-none primarytxt"
                        >
                          <i className="fa-regular fa-user me-2" />
                          Voir plus
                        </a>
                      </button>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          </div>
          </div>
           {show && (
            <div className="modal fade show d-block" tabIndex="-1">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Contact</h5>
                    <button type="button" className="close btn-0 " onClick={toggleModal}>
                      <span className="txt-xl" >&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <ContactForm setShow={setShow} />
                  </div>
                </div>
              </div>
            </div>
          )}
          {show && <div className="modal-backdrop fade show"></div>}
          </>
  )
}
