import React from "react";
import "./style.css";
import apiMethods from "../../config/services/ApiService";
import { useCallback } from "react";
import { BASE_URL } from "../../config/constants/apiEndpoints";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import images from "../../config/images";
import { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar, A11y, Pagination ,Navigation} from 'swiper/modules';
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { useDateFormat } from "../../config/hooks/general";
import { Nav } from "../../components/Nav";
import { BorderTitle } from "../../components/titles/BorderTitle";
import { Events } from "./components";
const Index = () => {
  const params = useParams();
  const [userinfo, setUserinfo] = useState({});

  const [tabs, setTabs] = useState(0);

  const [loading, setLoading] = useState(false);
  const [loadingError, setLoadingError] = useState(false);

  const noimage = BASE_URL + "istockphoto-1131164548-610x610.png";

  const fetchUserInfo = async () => {
    setLoading(true)
    try {
      const response = await apiMethods.getEventsById(params.id);
      if (response.data) {
        const userData = response.data;
        setUserinfo(userData);
        setLoading(false)
      } else {
      }
    } catch (error) {
      console.error("Error fetching userinfo: ", error);
      setLoadingError(true);
      setLoading(false)
    }

  };

  const [eventsList, setEventsList] = useState([]);
    const fetchEvetns = async () => {
      try {
        const response = await apiMethods.getEvents();
        console.log(response.data);
        setEventsList(response.data); // Assuming the response data is an array of levels
        
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };
  

  

  useEffect(() => {
    fetchEvetns();
    fetchUserInfo();
  }, [params.id]);
  const dateString = userinfo.created_at;
  const formatDate = useDateFormat(dateString);
 
  return (
    <>
{loading === false && loadingError === false ? (
     <>
     <Nav />
  <div
  className="container-fluid whitebg p-0 m-0 pt-5"
  style={{ minHeight: "100vh" }}
 >

    <div
  className="container h-100 "
  style={{  }}
 >
    <div
  className="row h-100"
  style={{  }}
 >
    <div
  className="col-12 col-md-8  h-100"
  style={{  }}
 >
  <h4 className="blacktxt fw-bold" >{userinfo.name}</h4>
  <div
        className="row m-0 p-0  w-100 mt-1  "
        style={{
          minHeight: "400px",
          width: "100%",
          background: `url("${
            userinfo.image
              ? BASE_URL + userinfo.image
              : images.noimage
          }")`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",borderRadius:4,boxShadow:'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',zIndex:1
        }}
      />
      <p className=" txt-md primarytxt fw-bold mt-1 " style={{marginBottom:0}} >Booking Architecture - Admin</p>
      <i class="fa-regular fa-clock txt-sm graytxt me-1"></i> <span className="txt-sm graytxt" >{formatDate}</span>
      <p style={{fontSize: "1.125em",    lineHeight: '150%'}} className="blacktxt txt-sm mt-3" >
        {userinfo.description}
      </p>
</div>
<div
  className="col-12 col-md-4  h-100"
  style={{  }}
 >
 <BorderTitle title={"LAST EVENTS"} />

 {eventsList.length!==0 ?  (
  
  <div className="mt-4" >
 {
    eventsList
    .filter(item => String(item.id) !== String(params.id))
    .slice(0, 3)
    .map(item => 
      <Link to={`/event/${item.id}`}>
    <Events key={item.id} item={item} />
    </Link>
    )
}

  </div>
 )
 :
 ""
}


{eventsList.length!==0 ?  (
 
 <div className="mt-1" >
  <BorderTitle title={"SAME TYPE"} />

{
   eventsList
   .filter(item => String(item.id) !== String(params.id) && String(item.type) === String(userinfo.type) )
   .slice(0, 3)
   .map(item => <Events key={item.id} item={item} />)
}

 </div>
)
:
""
}


</div>
 </div>
  
 </div>
  
  </div>
  </>
      ) : null}
{loadingError === true ? (
  <span>Error</span>
) : null}

{loading === true ? (
  <div
    className="container-fluid d-flex bg-white p-0 m-0"
    style={{ minHeight: "100vh" }}
  >
    <div className='mx-auto my-auto'>
      <img src={images.spinner} style={{ width: 50, height: 50 }} />
    </div>
  </div>
) : null}
     </>
  
  );
};

export default Index;
