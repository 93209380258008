import { LOGIN_TYPE, LOGOUT_TYPE,ACCOUNTDETAILS_TYPE,ROLE_TYPE } from "../actions/auth";

const initialState = {
    token: null,
    roles:null,
    accountdetails: {
        firstname:null, lastname:null,email:null,type:null,category:null
    },
};

export default (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_TYPE:
            return { ...state, token: action.payload };
        case ROLE_TYPE:
                return { ...state, roles: action.payload };
        case LOGOUT_TYPE:
            return { ...state, token: null,roles:null };
            case ACCOUNTDETAILS_TYPE:
                const { firstname, lastname,email,type,category } = action.payload;
                return {
                    ...state,
                    accountdetails: {
                        firstname, lastname,email,type,category
                    },
                };
        default:
            return state;
    }
}
