import axios from 'axios';
import storage from 'redux-persist/lib/storage'
import {
	API_BASE_URL,
	LOGIN_CHECK_ENDPOINT,TOKEN_REFRESH_ENDPOINT
} from '../../constants/apiEndpoints';


export const LOGIN_TYPE = 'LOGIN';
export const LOGOUT_TYPE = 'LOGOUT';
export const ACCOUNTDETAILS_TYPE = 'ACCOUNTDETAILS';
export const ROLE_TYPE = 'ROLE_TYPE';


export const login = (username, password, callback = null, token = null) => async (dispatch, getState) => {
	try {
		const response = await axios.post(`${API_BASE_URL}${LOGIN_CHECK_ENDPOINT}`, { username, password }, {
			headers: { 'Content-Type': 'application/json' },
		});
		if (response.data?.token) {
			dispatch({
				type: LOGIN_TYPE,
				payload: response.data.token,
			});
			dispatch({
				type: ROLE_TYPE,
				payload: response.data.roles,
			});
			console.log('Login successful!');
		} else {
			console.log('Login failed!');
			console.log(response.data)
		}

		if (callback) {
			callback(response.data);
		}
	} catch (e) {
		console.error('Error:', e);
		if (callback) {
			callback({ code: 401 }); // Keeping this as 401 for now, but you might want to update this based on the error details you observe
		}
	}
};

/* export const refresh = (callback = null) => async (dispatch, getState) => {
	const state = getState();
	try {
	  const request = await axios.post(API_BASE_URL+TOKEN_REFRESH_ENDPOINT, {
		refresh_token: state?.user?.token.refresh_token,
	  }, {
		headers: { 'Content-Type': 'application/json' },
	  });
	  if (request.data?.token) {
		dispatch({
		  type: LOGIN_TYPE,
		  payload: request.data,
		});
	  }
  
	  if (typeof callback === "function")
		callback(request.data);
	} catch (e) {
	  console.log(e);
	  if (callback)
		callback({ code: 401 });
	}
  }; */
  
  export const logout = (callback = null) => (dispatch) => {
	dispatch({
	  type: LOGOUT_TYPE,
	  payload: null,
	})
  
	if (typeof callback === "function")
	  callback();
  };

  export const setUserDetails = (detailsData) => ({
    type: ACCOUNTDETAILS_TYPE,
    payload: detailsData,
});
