import { BASE_URL } from "../../config/constants/apiEndpoints";
import { useDateFormat } from "../../config/hooks/general";
import images from "../../config/images";
import React, { useEffect, useState,useRef } from "react";
import apiMethods from '../../config/services/ApiService'
import './style.css'

import { Swiper, SwiperSlide } from 'swiper/react';
import { Scrollbar, A11y, Pagination ,Navigation} from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Outlet, Link } from "react-router-dom";
import { Nav } from './../../components/Nav';

const Counter = ({ targetCount }) => {
  const [count, setCount] = useState(0);
  
  const counterRef = useRef(null);

  useEffect(() => {
    const duration = 2000; // 2 seconds
    const maxIntervals = 60;
    const actualIntervals = Math.min(targetCount, maxIntervals); 
    const intervalDuration = duration / actualIntervals;
    const incrementStep = Math.ceil(targetCount / actualIntervals);

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          let currentCount = 0;

         
          const interval = setInterval(() => {
            currentCount += incrementStep;
            setCount(Math.min(currentCount, targetCount));
            if (currentCount >= targetCount) clearInterval(interval);
          }, intervalDuration);

         
          observer.unobserve(entry.target);
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 1.0,
      }
    );

    if (counterRef.current) observer.observe(counterRef.current);


    return () => observer.disconnect();
  }, [targetCount]);

  return (
    <span ref={counterRef} className="whitetxt txt-xxl fw-bold">
      {count}
    </span>
  );
};



export const Events = ({ item }) => {
    const dateString = item.created_at;
    const formatDate = useDateFormat(dateString);

    return(
        <div className="col-12 mb-3    ">
        <div className="salary whitebg raduis4 pb-5  h-100 position-relative  ">
          <div
            className=" mmyscaleimg-hover w-100 border-radius-top-4 "
            style={{
              background: `url("${item.image? BASE_URL+item.image : images.noimage }")`,
              backgroundPosition: "center",
              height: 200,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover"
            }}
          ></div>
          <div className="salary-details  whitebg pe-3 ps-3 pt-2 pb-2 border-radius-bottom-4">
            <span className="mt-1  primarytxt txt-md dotscompleter-1 ">
            {item.type}
            </span>
            <span className=" fw-bold blacktxt txt-md dotscompleter-2 ">
             {item.name}
            </span>
          </div>
          <div
            className="p-2 position-absolute "
            style={{ bottom: 0, width: "100%" }}
          >
            <hr className="mb-2 mt-4" />
            <span className="blacktxt txt-xs dotscompleter-1 ">
              Le {formatDate}
            </span>
          </div>
        </div>
      </div>
    )
}


export const EventsRender = () => {

    const [eventsList, setEventsList] = useState([]);
    const fetchEvetns = async () => {
      try {
        const response = await apiMethods.getEvents();
        console.log(response.data);
        setEventsList(response.data); // Assuming the response data is an array of levels
        
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };
  

    useEffect(() => {
        fetchEvetns();
      }, []);
      

    return(
        <>
         <div className="row mt-5 mb-3" id="events">
      <div className="col-12 text-center">
        <span className=" primarytxt txt-md ">Nos articles récents</span>
        <br />
        <span className=" blacktxt txt-lg fw-bold ">
          Actualités Et Evénements
        </span>
      </div>
    </div>
    <div className="container">
      <div className="row">
      {eventsList.length!==0 ?  (
      <Swiper
              slidesPerView={1}
              spaceBetween={10}
              // pagination={{
              //   clickable: true,
              // }}
              breakpoints={{
                640: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 50,
                },
              }}
              // modules={[Pagination]}
              className="mySwiper"
      >
        {eventsList.map((item, index) => {
          return(
        <SwiperSlide key={index} >
            <Link to={`/event/${item.id}`} className="text-decoration-none" >
          <Events item={item} />
          </Link>
        </SwiperSlide>
        )}
        )
            }
        </Swiper>)

:
       
<div className="text-center align-items-center" style={{
    height: 450,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
}}>
    <div>
        <img className="img-fluid" alt="" src={images.nodata} style={{width:"300px"}} />
        <p className="primarytxt mt-2 txt-sm">No data available</p>
    </div>
</div>



}

      </div>
    </div>
        </>
    )
}

export const ProfilesRender = () => {
    const [categoriesList, setCategoriesList] = useState([]);
    const fetchCategories = async () => {
      try {
        const response = await apiMethods.getCategories();
        console.log(response.data);
        setCategoriesList(response.data); // Assuming the response data is an array of levels
        
      } catch (error) {
        console.error("Error fetching Categories:", error);
      }
    };
  
    const [profcat, setProfcat] = useState(0);
    const [profilesList, setProfilesList] = useState([]);
    const fetchProfiles = async () => {
      try {
        let response;
        if(profcat===0){
         response = await apiMethods.getAllProfiles();
         setProfilesList(response.data.data); 
        }else{
          response = await apiMethods.getProfilesByCategory(profcat);
          setProfilesList(response.data); 
        }
   
       
 
        
      } catch (error) {
        console.error("Error fetching Profiles:", error);
      }
    };
  
  
  

    useEffect(() => {
        fetchCategories();
        fetchProfiles();
      }, [profcat]);
      
      const specialProfiles = profilesList.filter(item => item.user.pack === 2).slice(0, 1);
      const remainingProfiles = profilesList.filter(item => !specialProfiles.includes(item));
    return(
        <>
            <div className="row mt-5">
      <div className="col-12 text-center mb-3">
        <span className=" primarytxt txt-md ">Les mieux notés</span>
        <br />
        <span className=" blacktxt txt-lg fw-bold ">
          Les Professionnels Récents
        </span>
      </div>
      <div className="col-12 mb-4">
        <div className="container">
          <div className="tabs text-center">
            <div className="tabs-items me-auto ms-auto align-self-center w-auto  d-sm-inline-flex    ">
              <div className=" row-scrollable">
                <div className="col-auto p-0">
                  <button className='btn-0' onClick={()=>setProfcat(0)} >
                  <div className={
        "tab-item pe-3 ps-3 pt-2 pb-2" + 
        (profcat === 0 ? " selected-tabs-item" : "")
      }>
                    <span className="txt-md">Tous</span>
                  </div>
                  </button>
                </div>
                {categoriesList.map((item, index) => (
 <div className="col-auto p-0">
 <button className='btn-0' onClick={()=>setProfcat(item.id)} >
 <div className={
"tab-item pe-3 ps-3 pt-2 pb-2" + 
(profcat === item.id ? " selected-tabs-item" : "")
}>
     <span className="txt-md">{item.name}</span>
   </div>
   </button>
 </div>
                )
                )
                }
               

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="container">
      <div className="row">
      {profilesList.length!==0 ?  (
      <Swiper
              slidesPerView={1}
              spaceBetween={10}
             // pagination={{
              //  clickable: true,
             // }}
              breakpoints={{
                640: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 50,
                },
              }}
              //modules={[Pagination]}
              className="mySwiper"
      >
       {specialProfiles.map((item, index) => (
        <SwiperSlide key={index} >
            <Link style={{textDecoration:'none'}} to={`/profile/${item.id}`}>
        <div className=" col-12 mb-3  h-100  ">
          <div className="salary-spons whitebg raduis4 pb-5  h-100 position-relative  ">
            <div
              className=" mmyscaleimg-hover w-100 border-radius-top-4 "
              style={{
                background: `url("${ item.avatar ? BASE_URL+ item.avatar : images.noimage}")`,
                backgroundPosition: "center",
                height: 250,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
            ></div>
            <div className="salary-details  whitebg pe-3 ps-3 pt-2 pb-2 border-radius-bottom-4">
              <span className=" fw-bold blacktxt txt-md dotscompleter-1 ">
             {item.slogo ? item.slogo : "Indisponible"}
              </span>
              <span className="mt-1  blacktxt txt-xs dotscompleter-1 ">
                {" "}
                <i className="me-1 fa-solid fa-location-dot primarytxt txt-xs " />{" "}
                {item.address ? item.address : "Indisponible"}
              </span>
              <span className="mt-1  blacktxt txt-xs dotscompleter-1 ">
                {" "}
                <i className="me-1 fa-solid fa-phone primarytxt txt-xs " />
                {item.officenumber ? item.officenumber : "Indisponible"}
              </span>
              <div className="row  mt-3" style={{maxHeight:60}}>
              {item.specialities.map((speciality, index) => {
    return index < 3 ? (
        <div key={index} className="col-auto ps-1 pe-2 mb-2 ">
            <div className='   primary_badge ' style={{}} >
                           {speciality.name}
                                </div>
        </div>
    ) : null;
})}


              </div>
            </div>
            <div
              className="p-2 mt-auto position-absolute whitebg"
              style={{ bottom: 10, width: "100%" }}
            >
              <hr className="mb-2 mt-2" />
              <div className="row mt-1">
                <div className="col">
                <i className={`${item.averageStarRating > 0 ? "fa-solid " : "fa-regular"} fa-star yellowtxt txt-sm`} />
                <i className={`${item.averageStarRating > 1 ? "fa-solid " : "fa-regular"} fa-star yellowtxt txt-sm`} />
                <i className={`${item.averageStarRating > 2 ? "fa-solid " : "fa-regular"} fa-star yellowtxt txt-sm`} />
                <i className={`${item.averageStarRating >3 ? "fa-solid " : "fa-regular"} fa-star yellowtxt txt-sm`} />
                <i className={`${item.averageStarRating >4 ? "fa-solid " : "fa-regular"} fa-star yellowtxt txt-sm`} />

                </div>
                <div className="col-auto">
                  <i className=" fa-solid fa-arrow-right primarytxt" />
                </div>
              </div>
            </div>
          </div>
        </div>
    </Link>
        </SwiperSlide>
        ))
}

        {remainingProfiles.map((item, index) => (
        <SwiperSlide key={index} >
            <Link style={{textDecoration:'none'}} to={`/profile/${item.id}`}>
        <div className=" col-12 mb-3  h-100  ">
          <div className="salary whitebg raduis4 pb-5  h-100 position-relative  ">
            <div
              className=" mmyscaleimg-hover w-100 border-radius-top-4 "
              style={{
                background: `url("${ item.avatar ? BASE_URL+ item.avatar : images.noimage}")`,
                backgroundPosition: "center",
                height: 250,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
            ></div>
            <div className="salary-details  whitebg pe-3 ps-3 pt-2 pb-2 border-radius-bottom-4">
              <span className=" fw-bold blacktxt txt-md dotscompleter-1 ">
             {item.slogo ? item.slogo : "Indisponible"}
              </span>
              <span className="mt-1  graytxt txt-xs dotscompleter-1 ">
                {" "}
                <i className="me-1 fa-solid fa-location-dot primarytxt txt-xs " />{" "}
                {item.address ? item.address : "Indisponible"}
              </span>
              <span className="mt-1  graytxt txt-xs dotscompleter-1 ">
                {" "}
                <i className="me-1 fa-solid fa-phone primarytxt txt-xs " />
                {item.officenumber ? item.officenumber : "Indisponible"}
              </span>
              <div className="row mt-3" style={{maxHeight:60}}>
              {item.specialities.map((speciality, index) => {
    return index < 3 ? (
        <div key={index} className="col-auto ps-1 pe-2 mb-2 ">
            <div className="salary-skills pt-1 pb-1 pe-2 ps-2 raduis2 ">
                <span className="blacktxt txt-xs dotscompleter-1 ">
                    {speciality.name}
                </span>
            </div>
        </div>
    ) : null;
})}


              </div>
            </div>
            <div
              className="p-2 mt-auto position-absolute bg-white "
              style={{ bottom: 10, width: "100%" }}
            >
              <hr className="mb-2 mt-2" />
              <div className="row mt-1">
                <div className="col">
                <i className={`${item.averageStarRating > 0 ? "fa-solid " : "fa-regular"} fa-star yellowtxt txt-sm`} />
                <i className={`${item.averageStarRating > 1 ? "fa-solid " : "fa-regular"} fa-star yellowtxt txt-sm`} />
                <i className={`${item.averageStarRating > 2 ? "fa-solid " : "fa-regular"} fa-star yellowtxt txt-sm`} />
                <i className={`${item.averageStarRating >3 ? "fa-solid " : "fa-regular"} fa-star yellowtxt txt-sm`} />
                <i className={`${item.averageStarRating >4 ? "fa-solid " : "fa-regular"} fa-star yellowtxt txt-sm`} />

                </div>
                <div className="col-auto">
                  <i className=" fa-solid fa-arrow-right primarytxt" />
                </div>
              </div>
            </div>
          </div>
        </div>
    </Link>
        </SwiperSlide>
        ))
}
</Swiper>)

:
       
<div className="text-center align-items-center" style={{
    height: 450,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
}}>
    <div>
        <img className="img-fluid" alt="" src={images.nodata} style={{width:"300px"}} />
        <p className="primarytxt mt-2 txt-sm">No data available</p>
    </div>
</div>



}
    
      </div>
      <div className="col-12 text-center mt-4" >
      <button className="primarybtn primarytxt  ">
            <Link to={"/listing"} className="text-decoration-none primarytxt">
              Voir plus
              </Link>
            
          </button>
          </div>
    </div>
        </>
    )
}


export const WhoRender = () => {

return(
    <>
       <div id="who" className="row half-background-2 mt-5 pt-5 pb-5 ">
      <div className="col-12  text-center mb-4">
        <span className=" primarytxt txt-md ">A propos</span>
        
        <br />
        <span className=" blacktxt txt-lg fw-bold ">Qui Sommes nous</span>
      </div>
      <div className="col-12 order-1 order-md-0 col-md-6   mb-4  ">
        <div style={{ maxWidth: 500 }} className="ms-auto me-5 mt-5 mb-5 ">
          <span className="txt-md ">
          <span className="primarytxt txt-md  fw-bold ">  Booking Architecture </span> , est une nouvelle plateforme sélective et intelligente qui permet de trouver les
            talents professionnels liés au monde de l’architecture pour les études et exécution de vos projets.
          </span>
          <br />
          <br />
          <span className="  txt-md ">
            {" "}
            Sur le site web ou sur application,<span className="primarytxt txt-md  fw-bold ">  Booking Architecture </span>  vous facilite la recherche par ville ou sur la
base de mot clé lié à votre recherche.
          </span>
          <br />
          <br />
          <span className="  txt-md ">
            {" "}
            Cette plateforme répond au besoin permanent de trouver les bons profils qui vont accompagner les
maitres d’ouvrages (propriétaires de projets) depuis l’achat de terrain en passant par la phase
d’études préliminaires faites par la maitrise d’œuvre (Géomètre, Architectes, ingénieurs bétons et
vrd , Architectes d’intérieurs, paysagistes ...) et en dernier lieu, les équipes et sociétés qui vont
accompagner les chantiers au cours de l’acte de bâtir.
          </span>
          <br />
               <br />
          <span className="  txt-md ">
            {" "}
            <span className="primarytxt txt-md  fw-bold ">  Booking Architecture </span>  vous donne l’accès à sa base de données de professionnels tel un annuaire de nouvelle
génération interactif lié à votre site et réseaux sociaux professionnels avec une multitude
d’avantages.
          </span>
          <br />
          <br />
          {/* <button className="primarybtn">
            <a
              href="#"
              className=" fw-bold txt-sm text-decoration-none primarytxt"
            >
              Voir plus
            </a>
          </button> */}
        </div>
      </div>
      <div
        className="col-12  order-0 order-md-1 col-md-6  "
        style={{ minHeight: "100%" }}
      >
        <div
          className="d-flex  h-100 w-100 radius4 how-bg  mmyscaleimg-hover"
          style={{
            background: `url("${images.who}")`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover"
          }}
        ></div>
      </div>
    </div>
    </>
)

}

export const As = () => {

  return(
      <>
         <div id="who" className="row half-background-2 mt-5 pt-5 pb-5 ">
        <div className="col-12  text-center mb-4">
          {/* <span className=" primarytxt txt-ms ">A propos</span>
          
          <br />
          <span className=" blacktxt txt-lg fw-bold ">Qui Sommes nous</span> */}
        </div>
        <div className="col-12 order-1 order-md-0 col-md-6    ">
          <div style={{ maxWidth: 500 }} className="ms-auto me-md-5 mb-5 ">
            <span className=" graytxt txt-md ">
              {" "}
              <span className=" fw-bold primarytxt txt-md ">
              Si vous êtes un professionnel
              </span><br></br><br></br>
              <ul>
               <li className="txt-md blacktxt" >
                Pour exister parmi les nombreux professionnels de l’architecture au Maroc inscris, faire
briller votre nom grâce aux nombreuses fonctionnalités proposées et nourrir votre E-
réputation, grâce au système de notation en toute transparence.
                </li>
               <li className="txt-md blacktxt" >
                Pour Bénéficier d’une page gratuite contenant toutes vos informations, réalisations ainsi que
l’accès à votre géolocalisation, ce qui facilite l’accès à votre profil par le client souhaitant un
professionnel dans sa zone.
                  </li>
                 <li className="txt-md blacktxt" >
                  Pour Accéder à un Wall de partage, vous permettant de tisser de nouvelles relations et
élargir votre réseau, vous pourriez ainsi partager vos récentes réalisations et avoir l’avis de
vos confrères /consœurs ou carrément créer un forum de discussion sur les sujets
professionnels qui vous intéressent.
                  </li>
              </ul>
            </span>
            <br />
            <span className=" graytxt txt-md ">
              {" "}
              <span className=" fw-bold primarytxt txt-md ">
              Si vous êtes une société 
              </span><br></br><br></br>
            
<ul>
               <li className="txt-md blacktxt " >
                Pour créer de nouveaux partenariats, et répondre en temps réel aux besoins des
professionnels se rapportant a votre domaine d’intervention.
                </li>
               <li className="txt-md blacktxt" >
                Pour bénéficier d’une vitrine et exposer vos services aux demandeurs.
                  </li>
                 
              </ul>

            </span>
            <br />
            <span className=" graytxt txt-md ">
              {" "}
              <span className=" fw-bold primarytxt txt-md ">
              Si vous êtes un particulier 
              </span><br></br><br></br>

              <ul>
                <li className="txt-md blacktxt" >
                Pour trouver le professionnel qui répond le mieux à vos critères et qui se situe dans la zone
géographique la plus proche de votre choix.
                </li>
               <li className="txt-md blacktxt" >
                Pour Accéder au E-book ou CV thèque de l’architecte de votre choix, pour vous inspirer et
avoir une idée de ces réalisations et de son style.
</li>
                 <li className="txt-md blacktxt" >
                  Pour Booker un RDV par un simple clic et accéder directement au contact du professionnel
de votre choix.
                  </li>
              </ul>
      

            </span>
            <br />
        
           
            <br />
            <br />
            {/* <button className="primarybtn">
              <a
                href="#"
                className=" fw-bold txt-sm text-decoration-none primarytxt"
              >
                Voir plus
              </a>
            </button> */}
          </div>
        </div>
        <div
          className="col-12  order-0 order-md-1 col-md-6 mb-md-4  "
          style={{ minHeight: "100%" }}
        >
          <div
            className="d-flex  h-100 w-100 radius4 how-bg  mmyscaleimg-hover"
            style={{
              background: `url("https://assets.vogue.in/photos/5ed0b95f02e117a6ff057633/4:3/w_3848,h_2886,c_limit/Online%20architecture%20courses.jpg")`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover"
            }}
          ></div>
        </div>
      </div>
      </>
  )
  
  }

export const HowRender = () => {

    return(
        <>
         <div className="row half-background mt-5 pt-5 pb-5 ">
      <div className="col-12 text-center mb-4">
        <span className=" primarytxt txt-md ">Booking Architecture</span>
        <br />
        <span className=" blacktxt txt-lg fw-bold ">Les avantages </span>
      </div>
      <div className="col-12 col-md-6  mb-4  " style={{ minHeight: "100%" }}>
        <div
          className="d-flex h-100 w-100 radius4 how-bg mmyscaleimg-hover "
          style={{
            background: `url("${images.how}")`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover"
          }}
        ></div>
      </div>
      <div className="col-12 col-md-6  ">
        <div className="row ps-md-2 ">
   
          <div className="col">
            {/* <span className=" blacktxt txt-l fw-bold ">
              Amet consectetur adipisicing.
            </span> */}
            <p className=" blacktxt txt-md " style={{ maxWidth: 400 }}>
          <span className="txt-lg primarytxt" >1-</span> Exister sur une plateforme de nouvelle génération regroupant les professionnels par communauté
lié au monde de l’architecture au Maroc.
            </p>
          </div>
        </div>
        <div className="row ps-md-2 ">
       
          <div className="col">
            {/* <span className=" blacktxt txt-l fw-bold ">
              Lorem ipsum dolor sit amet
            </span> */}
       
             <p className=" blacktxt txt-md " style={{ maxWidth: 400 }}>
            <span className="txt-lg primarytxt" >2-</span> Faciliter l’accès et la recherche vers votre profil, sur le marché marocain avec la géolocalisation de
votre siège sur Map.
            </p>
          </div>
        </div>
        <div className="row ps-md-2 ">
       
       <div className="col">
            {/* <span className=" blacktxt txt-l fw-bold ">
              Lorem ipsum dolor sit{" "}
            </span> */}

             <p className=" blacktxt txt-md " style={{ maxWidth: 400 }}>
            <span className="txt-lg primarytxt" >3-</span>Visualisation de vos services et références, sur la base de vos publications (photos et vidéos) dans
votre page vitrine professionnelle.
            </p>
          </div>
        </div>
        <div className="row ps-md-2 ">
       
       <div className="col">
            {/* <span className=" blacktxt txt-l fw-bold ">
              Lorem ipsum dolor sit amet
            </span> */}
      
             <p className=" blacktxt txt-md " style={{ maxWidth: 400 }}>
            <span className="txt-lg primarytxt" >4-</span>Création de nouveaux partenariats entre professionnels dans le monde de l’architecture dans tout
le Maroc entre les prestataires de services et sociétés pour l’exécution de vos projets.
            </p>
          </div>
        </div>
        <div className="row ps-2 ">
          <div className="col-auto">
            {/* <i className="fa-solid fa-shield-halved secondarytxt txt-xl " /> */}
          </div>
          <div className="col">
            {/* <span className=" blacktxt txt-l fw-bold ">
              Lorem ipsum dolor sit amet
            </span> */}
      
            {/*  <p className=" blacktxt txt-md " style={{ maxWidth: 400 }}>
            <span className="txt-lg primarytxt" >5-</span> Booster votre presence et votre identitée  profesionelle sur une plate forme marocaine qui demande vos services et competence . 
            </p> */}
          </div>
        </div>
        <div className="row ps-2 ">
          <div className="col-auto">
            {/* <i className="fa-solid fa-shield-halved secondarytxt txt-xl " /> */}
          </div>
          <div className="col">
            {/* <span className=" blacktxt txt-l fw-bold ">
              Lorem ipsum dolor sit amet
            </span> */}
      
            {/*  <p className=" blacktxt txt-md " style={{ maxWidth: 400 }}>
            <span className="txt-lg primarytxt" >6-</span> Mise a niveaux visuelle de vos  references sur votre  page vitrine qui va refleter votre savoir faire pour les chercheurs de services partout au maroc .
            </p> */}
          </div>
        </div>
      </div>
    </div>
        </>
    )
    
    }


    export const CategoriesRender = () => {

        const [categoriesList, setCategoriesList] = useState([]);
        const fetchCategories = async () => {
          try {
            const response = await apiMethods.getCategories();
            console.log(response.data);
            setCategoriesList(response.data); // Assuming the response data is an array of levels
            
          } catch (error) {
            console.error("Error fetching Categories:", error);
          }
        };
      
    
        useEffect(() => {
            fetchCategories();
          }, []);
          


            return(
                <>

                  <div className="row mt-5 " id="categories">
      {/* title */}
      <div className="col-12 text-center mb-3">
        <span className=" primarytxt txt-md ">Découvrez Nos</span>
        <br />
        <span className=" blacktxt txt-lg fw-bold ">Catégories</span>
      </div>
      {/* title end  */}
      {/* content   */}
      <div className="col-12 mt-2">
        <div className="container">
          <div className="row ">
          {categoriesList.map((item, index) => {
          return(
         
            <div key={index} className={(index < 2 ? "col-md-6" : "col-md-4") + " col-sm-12 mb-3"}>
   <Link style={{textDecoration:'none'}} to={`/listing`}>
              <div
                className="  category-hover raduis4"
                style={{
                  background: `url("${ BASE_URL+ item.image}")`,
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover"
                }}
              >
                <div
                  className=" d-flex p-4 raduis4 category-hover-content "
                  style={{ height: "100%", minWidth: "100%" }}
                >
                  <div className="mt-auto">
                    <span className=" fw-bold mt-auto txt-lg whitetxt ">
                      {item.name}
                    </span>
                    <br />
                    <span className=" mt-auto txt-sm whitetxt ">
                      Voir la liste
                    </span>
                    <i className="ms-2 fa-solid fa-arrow-right whitetxt" />
                  </div>
                </div>
              </div>
              </Link>
            </div>
           
          )
            }
          )
            }

          </div>
        </div>
      </div>
      {/* content end  */}
    </div>

                </>
            )

    }


    export const HeaderRender = () => {

        const [isMobile, setIsMobile] = useState(window.innerWidth <= 850);
        const [isMenuOpen, setIsMenuOpen] = useState(false);
      
        useEffect(() => {
          const handleResize = () => {
            setIsMobile(window.innerWidth <= 850);
          };
          window.addEventListener("resize", handleResize);
          return () => {
            window.removeEventListener("resize", handleResize);
          };
        }, []);
        
        useEffect(() => {
          const checkAnimation = () => {
            // ... (your checkAnimation function logic)
          };
          window.addEventListener("scroll", checkAnimation);
          return () => {
            window.removeEventListener("scroll", checkAnimation);
          };
        }, []);
        
        const handleMenuToggle = () => {
          setIsMenuOpen(!isMenuOpen);
        };
        
      
        const circles = Array.from({ length: 30 }, (_, index) => ({
          size: Math.random() * !isMobile ? 100 + 50 : 50 + 20 , // Random size between 50 and 150
          left: Math.random() * 200, // Random left position (percentage)
          delay: Math.random() * 5, // Random animation delay up to 5s
      }));
      

        return(
            <>
               <div id="header" className="row   ">
      <section
        style={{
          background: `url("${images.landingbg}")`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover"
        }}
        className="header-section"
      >

{circles.map((circle, index) => (
                <div
                    key={index}
                    className="circle"
                    style={{
                        width: `${circle.size}px`,
                        height: `${circle.size}px`,
                        left: `${circle.left}%`,
                        animationDelay: `${circle.delay}s`,
                        bottom: `-${circle.size / 2}px`, // To ensure it starts outside of the view
                    }}
                ></div>
            ))}
          <div className="circle circle1"></div>
            <div className="circle circle2"></div>
            <div className="circle circle1"></div>
            <div className="circle circle2"></div>

        <div className="container   h-100 ">
                    <Nav variant={0} />
          <div className="row  mt-auto mb-auto mx-auto  ">
          <div className="col-2 " />
            <div className="col-10   mx-auto text-center justify-content-center" style={{}} >
           
            <div className=" mx-auto text-center justify-content-center" style={{maxHeight: "50vh",position:'relative',backgroundColor:"red",width:'auto'}}>


<img style={{position:'absolute',top:0,left:0,maxWidth:'80%' }}
                className="myscaleimg-2 img-fluid"
                src={images.s1}

               
              />
              <img style={{position:'absolute',top:0,left:0,maxWidth:'80%' }}
                className="myscaleimg-2 img-fluid"
                src={images.s2}

               
              />
              <img style={{position:'absolute',top:0,left:0,maxWidth:'80%' }}
                className="myscaleimg-2 img-fluid"
                src={images.s3}

               
              />
              <img style={{position:'absolute',top:0,left:0,maxWidth:'80%' }}
                className="myscaleimg-2 img-fluid"
                src={images.s4}

               
              />
              <img style={{position:'absolute',top:0,left:0,maxWidth:'80%' }}
                className="myscaleimg-2 img-fluid"
                src={images.s5}

               
              />
              
              <img style={{position:'absolute',top:0,left:0,maxWidth:'80%' }}
                className="myscaleimg-2 img-fluid"
                src={images.s6}

               
              />
              <a href="#categories" className=" text-decoration-none " >
               <img style={{position:'absolute',top:0,left:0,maxWidth:'80%' }}
                className=" img-fluid"
                src={images.s7}

               
              />
              </a>
              <a href="#categories" className=" text-decoration-none " >
               <img style={{position:'absolute',top:0,left:0,maxWidth:'80%' }}
                className=" img-fluid"
                src={images.s8}

               
              />
              </a>   <a href="#categories" className=" text-decoration-none " >
               <img style={{position:'absolute',top:0,left:0,maxWidth:'80%' }}
                className=" img-fluid"
                src={images.s9}

               
              />
              </a>   <a href="#categories" className=" text-decoration-none " >
               <img style={{position:'absolute',top:0,left:0,maxWidth:'80%' }}
                className=" img-fluid"
                src={images.s10}

               
              />
              </a>   <a href="#categories" className=" text-decoration-none " >
               <img style={{position:'absolute',top:0,left:0,maxWidth:'80%' }}
                className=" img-fluid"
                src={images.s11}

               
              />
              </a>   <a href="#categories" className=" text-decoration-none " >
               <img style={{position:'absolute',top:0,left:0,maxWidth:'80%' }}
                className=" img-fluid"
                src={images.s12}

               
              />
              </a>
                 </div>
      
                 </div>
        
          </div>
        </div>
      </section>
    </div>
            </>
        )
    }

    export const NumbersRender = () => {

      return(
      <>

         <div className="container-fluid mt-5 pt-5 pb-5  primarybg">
       <div className="container pt-4 pb-4 ">
       <div className="row ">
       <div className="col-md-4 col-sm-6 col-12 text-center ">
        <img src={images.prof_type} className="mb-1" style={{width:60,height:60}} /><br></br>
        <Counter targetCount={7516} /><br></br>
        <span  className=" whitetxt txt-sm">Professionnels</span>
        </div>
        <div className="col-md-4 col-sm-6 col-12 text-center ">
        <img src={images.etudiant} className="mb-1" style={{width:60,height:60}} /><br></br>
        <Counter targetCount={4816} /><br></br>
        <span  className=" whitetxt txt-sm">Autres</span>
        </div>
        <div className="col-md-4 col-sm-6 col-12 text-center ">
        <img src={images.profile} className="mb-1" style={{width:60,height:60}} /><br></br>
        <Counter targetCount={2516} /><br></br>
        <span  className=" whitetxt txt-sm">Utilisateurs Actifs</span>
        </div>
        </div>
        </div>
        </div>
      </>
      )

}