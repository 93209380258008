import images from "../../../config/images";
import React, { useEffect, useState } from "react";
import "./style.css";
import apiMethods from "../../../config/services/ApiService";
import { BASE_URL } from "../../../config/constants/apiEndpoints";
import { CustomModal } from "../../../components/modal";
import Dashboard from "../index";

const Index = () => {
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isAddModalOpen, setADDModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [name, setName] = useState("");
  const [color, setColor] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const [categoriesList, setProfilesRequestsList] = useState([]);

  const onFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const deleteModel = async (id) => {
    setButtonLoading(true);
    try {
      const response = await apiMethods.deleteProfilesRequests(id);
      if (response.data) {
        setDeleteModalOpen(false);
      }
    } catch (error) {
      console.error("Error fetching ProfilesRequests:", error);
    }
    fetchProfilesRequests();
    setButtonLoading(false);
    setDeleteModalOpen(false);
  };


  const generatePassword = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let password = '';
    for (let i = 0; i < 10; i++) {
        password += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return password;
};

  const updateRequest = async (id,status,item) => {
    const newPassword = generatePassword();
    const data = {
      "status":status
    }
    setButtonLoading(true);
    try {
      const response = await apiMethods.updateProfileRequest(id,data);
    
      if (response) {
        if(status==1){
          const emaildata = {
            "email":item.email,
            "password":newPassword
          }
          const response2 = await apiMethods.updateUsers(item.profile.user.id,emaildata);
          if(response2){
          const response3 = await apiMethods.postAccountAccess(emaildata);
          if(response3){
            fetchProfilesRequests();
          }
        }

          
        }
      }
    } catch (error) {
      console.error("Error fetching ProfilesRequests:", error);
    }
  };



  const fetchProfilesRequests = async () => {
    setLoading(true);
    try {
      const response = await apiMethods.getProfileRequest(search);
      if (response.data) {
        setProfilesRequestsList(response.data);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching ProfilesRequests:", error);
    }
  };

  useEffect(() => {
    fetchProfilesRequests();
  }, [search]);

  return (
<Dashboard>
    <>
      <div className="">
        {/* page title */}
        <span className=" txt-l blacktxt fw-bold ">ProfilesRequests</span>
        <br></br>
        <span className=" txt-xs graytxt">Dashboard / ProfilesRequests</span>
        {/* page title end  */}
        {/* data card */}
        <div className="a-card-container whitebg mt-3 ">
          <div className="p-2 ">
            {/* first row */}
            <div className="row mb-3">
              {/* search */}
               <div className="col-auto  my-auto mb-2">
                <div className="d-flex  lightbg btn-radius ">
                  <svg
                    className="mx-auto my-auto ms-2"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      opacity="0.5"
                      x="17.0365"
                      y="15.1223"
                      width="8.15546"
                      height="2"
                      rx="1"
                      transform="rotate(45 17.0365 15.1223)"
                      fill="currentColor"
                    ></rect>
                    <path
                      d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                  <input
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    className="ms-2 lightbg inputs-container"
                    placeholder="Rechereche"
                  />
                </div>
              </div> 
              {/* search end */}
              {/* add button  */}
              {/* <div className="col-auto ms-auto  ">
                <button
                  className="a-btn  primary txt-sm "
                  onClick={() => {
                    setName("");
                    setColor("");
                    setSelectedFile("");
                    setSelectedItem("");
                    setADDModalOpen(true);
                  }}
                >
                  Ajouter Un Profile
                </button>
              </div> */}
              {/* add button end */}
            </div>
            {/* first row end */}
            {/* second row */}
            <div className="row ">
              
              <div className="table-container" >
                {!loading ? (
                  <table>
             
                    <tr>
                    <th>Profile</th>
                    <th>Requested_By</th>
                    <th>Email</th>
                    <th>Number</th>
                 
                    <th>Reason</th>
                      <th>Actions</th>
                    </tr>
                   
                    {categoriesList.map((item, index) => {
                      return (
                        <>
                          <tr key={index}>
                            <td>

                            <div
                                className="row"
                                style={{ flexWrap: "nowrap" }}
                              >
                                     <div className="col-auto">
                              <div
                                className=""
                                style={{
                                  background: `url("${item.profile.avatar ? BASE_URL + item.profile.avatar : images.noimage}")`,
                                  backgroundPosition: "center",
                                  height: 50,
                                  width: 50,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "cover",
                                  borderRadius: "100%",
                                }}
                              ></div>
                                 </div>
                                    <div className="col-auto my-auto">
                                    
                              <span  className=" dotscompleter-1 txt-sm">{item.profile.slogo}</span>
                              </div>
                         
                              </div>
                            </td>
 
                            <td>
                            <span  className=" txt-sm dotscompleter-1">{item.firstname}  {item.lastname}</span>
                            
                            </td>
                            <td>
                            <span  className=" txt-sm dotscompleter-1">{item.email}  </span>
                            
                            </td>
                            <td>
                            <span  className=" txt-sm dotscompleter-1">{item.number}  </span>
                            
                            </td>
                            <td>
                            <span  className=" txt-sm dotscompleter-1">{item.details ? item.details: "no Details" }  </span>
                            
                            </td>
                            
                            <td className="actions">
                              <div
                                className="row"
                                style={{ flexWrap: "nowrap" }}
                              >
                   
                                  {item.status ==0 && <>
                                <div className="col-auto">
                                  <button
                                    className="btn-0"
                                    onClick={() => {
                                     
                                      updateRequest(item.id,1,item);
                                    }}
                                  >
                                  <div className="" style={{backgroundColor:'#d1e7dd',borderRadius:4,width:25,height:25}} >
                                  <i class="fa-solid fa-check txt-xs mx-auto my-auto" style={{color:'#0a3622'}}></i>
                                  </div>
                                  </button>
                                </div>
                                <div className="col-auto">
                                  <button
                                    className="btn-0"
                                    onClick={() => {
                                    
                                      updateRequest(item.id,2);
                                    }}
                                  >
                                  <div className="" style={{backgroundColor:'#f1aeb5',borderRadius:4,width:25,height:25}}>
                                  <i class="fa-solid fa-xmark txt-xs mx-auto my-auto" style={{color:'#58151c'}}></i>
                                  </div>
                                  </button>
                                </div>
                                </>
                                 }
                                    <div className="col-auto">
                                     {item.status ==1 && <>
                                      <div className="p-1" style={{backgroundColor:'#d1e7dd',borderRadius:4,}} >
                                  <span class=" txt-xs mx-auto my-auto  text-center" style={{color:'#0a3622'}}>Accepted</span>
                                  </div>
                                     </>
                                        }
                                        {item.status ==2 && <>
                                      <div className="p-1" style={{backgroundColor:'#f1aeb5',borderRadius:4,}} >
                                  <span class=" txt-xs mx-auto my-auto text-center" style={{color:'#58151c'}}>Refused</span>
                                  </div>
                                     </>
                                        }
                              </div>
                              </div>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </table>
                ) : (
                  <div className=" d-flex w-100 " style={{ height: 400 }}>
                    <img
                      className="mx-auto my-auto"
                      src={images.spinner}
                      style={{ width: 50 }}
                    />
                  </div>
                )}
              </div>





            </div>
            {/* second row  end*/}
          </div>
        </div>
        {/* data card end */}
      </div>

      <CustomModal
        isOpen={isDeleteModalOpen}
        onRequestClose={() => setDeleteModalOpen(false)}
        contentLabel="Example Modal"
      >
        <div className="col-12 d-flex mb-3">
          <img
            className="mx-auto"
            src={images.deleteicon}
            style={{ width: 80,height:100 }}
          />
        </div>
        <h4>Confirmer la suppression</h4>
        <p>
          Etes-vous sûr que vous voulez supprimer: <br></br>{" "}
          {selectedItem?.name} ?
        </p>
        <div className="row mt-2">
          <div className="col-12 d-flex">
            <div className="ms-auto ">
              <button
                className="a-btn primary me-2"
                onClick={() => {
                  deleteModel(selectedItem?.id);
                }}
              >
                {!buttonLoading ? (
                  "Yes"
                ) : (
                  <img
                    className="mx-auto my-auto"
                    src={images.spinner}
                    style={{ width: 20 }}
                  />
                )}
              </button>
              <button
                className="a-btn"
                onClick={() => setDeleteModalOpen(false)}
              >
                No
              </button>
            </div>
          </div>
        </div>
      </CustomModal>

   
    </>
</Dashboard>
  );
};

export default Index;
